/*
Version: 1.0
Author: グランソールジャパン
Author URI: grandsoul-japan.co.jp
 */
@charset "UTF-8";

// Import reset
@import "_reset.scss";

// Import slick
@import "_slick.scss";
@import "_slick-theme.scss";

// Import original basic modules
@import "_mixin.scss";
@import "_setting.scss";

// Import wordpress
@import "_wp.scss";

// Import original modules
@import "_module.scss";



// ----------------------------------------
// ヘッダー
// ----------------------------------------
.home {
    &.is-animation {
        .header {
            background-color: white;
            &-logo {
                .logo {
                    svg {
                        fill: $black;
                    }
                }
            }
            &-nav {
                li {
                    a {
                        color: $black;
                    }
                }
            }
            &-tel {
                color: $black;
            }
        }
    }
    .header {
        background-color: inherit;
        box-shadow: none;
        &-logo {
            .logo {
                svg {
                    fill: white;
                }
            }
        }
        &-nav {
            li {
                a {
                    color: white;
                }
            }
        }
        &-tel {
            color: white;
        }
    }
    #main {
        padding: 0;
    }
}
.header {
    z-index: 1000;
    position: fixed;
    width: 100%;
    background-color: white;
    &-inner {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        position: relative;
        padding: 8px 20px 12px;
    }
    &-logo {
        .logo {
            line-height: 1.0;
            svg {
                width: 214px;
                height: 50px;
                @include media(md) {
                    width: 347px;
                    height: 81px;
                }
                .st0 {
                    fill: $main;
                }
                .st1 {
                    fill: $key;
                }
            }
        }
    }
    &-nav {
        display: none;
        @include media(lg) {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-end;
        }
    }
    &-menu {
        @include media(md) {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
        }
        li {
            font-size: 0.875rem;
            line-height: 1.2;
            margin: 0 20px 0 0;
            a {
                display: inline-block;
                color: $black;
            }
        }
    }
    &-tel {
        margin: 0 20px 0 0;
        line-height: 1;
        color: $black;
        font-size: 1.25rem;
        font-weight: bold;
        img {
            display: inline-block;
            margin: 0 1px 0 0;
        }
    }
    &-contact {
        font-size: 0.875rem;
        line-height: 1.2;
        a {
            padding: 10px 20px;
            background-color: $main;
            color: white;
            display: inline-block;
            &:after {
                display: inline-block;
                width: 6px;
                height: 6px;
                transform: rotate(-45deg);
                border: 2px solid white;
                border-top-width: 0;
                border-left-width: 0;
                content: '';
                margin: 0 0 2px 6px;
            }
        }
    }
    &-en {
        display: none;
        margin: 0 0 0 20px;
        font-size: 0.875rem;
        line-height: 1.2;
        a {
            display: inline-block;
            padding: 10px;
            border-radius: 100px;
            background-color: $key;
            color: white;
        }
    }
    &-banner {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        position: absolute;
        right: 50%;
        bottom: -25px;
        transform: translate(50%, 0);
        @include media(md) {
            display: block;
            right: 20px;
            bottom: -80px;
            transform: none;
        }
        a {
            display: block;
            width: 130px;
            text-align: center;
            padding: 7px 5px;
            border-radius: 100px;
            line-height: 1;
            font-size: 0.75rem;
            @include media(md) {
                width: 190px;
                padding: 10px 5px;
                font-size: 1rem;
            }
            span {
                display: none;
                @include media(md) {
                    display: inline;
                }
            }
            &.job {
                border: 2px solid $main;
                background-color: white;
                color: $main;
                margin: 0 10px 0 0;
                @include media(md) {
                    margin: 0 0 10px;
                }
            }
            &.offer {
                border: 2px solid white;
                background-color: $main;
                color: white;
            }
        }
    }

}



#nav-drawer {
    position: relative;
    @include media(lg) {
        display: none;
    }
}
.nav-unshown {
    display:none;
}
#nav-open {
    display: inline-block;
    width: 30px;
    height: 22px;
    vertical-align: middle;
    transition: .3s;
    cursor: pointer;
}
#nav-open span, #nav-open span:before, #nav-open span:after {
    position: absolute;
    height: 3px;
    width: 25px;
    border-radius: 3px;
    background: $black;
    display: block;
    content: '';
    cursor: pointer;
    transition: .8s;
    .home & {
        background: white;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
    }
    .home.is-animation & {
        background: $black;
        box-shadow: none;
    }
}
#nav-open span:before {
    bottom: -8px;
}
#nav-open span:after {
    bottom: -16px;
}
#nav-close {
    display: none;
    position: fixed;
    z-index: 99;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0;
    transition: .3s ease-in-out;
}
#nav-content {
    overflow: auto;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9999;
    width: 90%;
    max-width: 280px;
    height: 100%;
    background: linear-gradient(-135deg, $key, $main);
    transition: .3s ease-in-out;
    transform: translateX(105%);
    padding: 20px;
    .en {
        display: none;
    }

}
#nav-input:checked ~ #nav-close {
    display: block;
    opacity: .5;
}
#nav-input:checked ~ #nav-open {
    z-index: 999;
    position: fixed;
    right: 280px;
}
#nav-input:checked ~ #nav-open span,
#nav-input:checked ~ #nav-open span:before,
#nav-input:checked ~ #nav-open span:after {
    background: white;
    z-index: 999;
}
#nav-input:checked ~ #nav-open span {
    transform: rotate(45deg);
}
#nav-input:checked ~ #nav-open span:before {
    bottom: 0;
    transform: rotate(-90deg);
}
#nav-input:checked ~ #nav-open span:after {
    bottom: 0;
    opacity: 0;
}
#nav-input:checked ~ #nav-content {
    transform: translateX(0%);
    box-shadow: -6px 0 25px rgba(0,0,0,.15);
}

#nav-menu {
    li {
        text-align: left;
        padding: 10px 0;
        border-bottom: 1px solid $main;
        a {
            display: block;
            color: white;
            &:hover {
                color: $link;
            }
        }
        ul {
            margin: 10px 0 0;
        }
        li {
            padding: 5px 0px 5px 20px;
            position: relative;
            border-bottom: none;
            &:before {
                content: '';
                position: absolute;
                width: 10px;
                height: 1px;
                background-color: $key;
                left: 0;
                top: 50%;
                transform: translate(0, -50%);
            }
        }
    }
}
.nav-banner {
    a {
        display: block;
        width: 100%;
        text-align: center;
        padding: 10px 5px;
        border-radius: 100px;
        line-height: 1;
        margin: 0 0 10px;
        &.job {
            border: 2px solid $main;
            background-color: white;
            color: $main;
        }
        &.offer {
            border: 2px solid white;
            background-color: $main;
            color: white;
        }
    }
}








// ----------------------------------------
// メイン
// ----------------------------------------
#main {
    display: block;
    z-index: 0;
    background-color: $thin;
    padding: 71px 0 0;
    @include media(md) {
        padding: 103px 0 0 0;
        width: 100%;
    }
}




// ファーストビュー
.wf-active {
    .sec-hero {
        .title {
            font-family: $font-min;
        }
    }
}
.sec-hero {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: calc(220px + 240*(100vw - 320px)/960);
    .title {
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: calc(30px + 30*(100vw - 320px)/960);
        font-weight: bold;
        transform: translate(-50%, -50%);
        z-index: 10;
        color: white;
        width: 90%;
        text-align: center;
        font-family: $font-load;
        line-height: 1.3;
        span {
            display: block;
            font-size: 1.25rem;
        }
    }
    .slider {
        width: 100%;
        height: calc(220px + 240*(100vw - 320px)/960);
        .slide {
            width: 100%;
            height: calc(220px + 240*(100vw - 320px)/960);
            overflow: hidden;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            &.slide1 { background-image: url(assets/images/mv-hero1.jpg); }
            &.slide2 { background-image: url(assets/images/mv-hero2.jpg); }
            &.slide3 { background-image: url(assets/images/mv-hero3.jpg); }
            &.slide4 { background-image: url(assets/images/mv-hero4.jpg); }
            &.slide5 { background-image: url(assets/images/mv-hero5.jpg); }
        }
    }
}


	ul.hero-panel li.panel34 {
		width:100%;
	}
	ul.hero-panel li.panel34 {
		width:100%;
	}
	ul.hero-panel li.panel33 img,ul.hero-panel li.panel34 img{
		width:100%;
	}	

@include media(md) {
	ul.hero-panel li.panel33 {
		width:33%;
		float:left;
	}
	ul.hero-panel li.panel34 {
		width:34%;
		float:left;
	}
}

ul.hero-panel img {
	width:100%;
	height:auto;
      border:solid 1px #CCC;
}


.wf-active {
    .page-head .sub,
    .post-head .sub {
        font-family: $font-en;
    }
}
.page-head {
    background: linear-gradient(-135deg, $key, $main);
    padding: 40px 20px;
    overflow: hidden;
    position: relative;
    @include media(md) {
        padding: 80px 20px;
    }
    .title {
        font-size: calc(20px + 20*(100vw - 320px)/960);
        letter-spacing: 10px;
        font-weight: 700;
        color: white;
        line-height: 1.0;
        text-align: center;
    }
    .sub {
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translate(-50%, 0);
        font-style: $font-load;
        font-size: 30px;
        letter-spacing: 2px;
        color: rgba(247, 245, 238, .4);
        line-height: 1.0;
        width: 95%;
        text-align: center;
        @include media(md) {
            font-size: 80px;
            bottom: -20px;
            letter-spacing: 5px;
        }
    }
}
.post-head {
    background: linear-gradient(-135deg, $key, $main);
    padding: 40px 20px;
    overflow: hidden;
    position: relative;
    @include media(md) {
        padding: 80px 20px;
    }
    .title {
        font-size: calc(20px + 20*(100vw - 320px)/960);
        letter-spacing: 2px;
        line-height: 1.3;
        font-weight: 700;
        color: white;
        line-height: 1.0;
        text-align: center;
    }
    .sub {
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translate(-50%, 0);
        font-size: 30px;
        font-style: $font-load;
        letter-spacing: 2px;
        color: rgba(247, 245, 238, .4);
        line-height: 1.0;
        width: 95%;
        text-align: center;
        @include media(md) {
            font-size: 80px;
            bottom: -20px;
            letter-spacing: 5px;
        }
    }
}

.sec-mv {
    position: relative;
    width: 100%;
    height: auto;
    background: center center / cover no-repeat;
    opacity: 0;
    transition: opacity 2s ease-in;
    &:before {
        display: block;
        padding-top: 160px;
        content: '';
        @include media(md) {
            padding-top: 320px;
        }
    }
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-family: 'object-fit: cover;';
        z-index: -1;
    }
    .action & {
        opacity: 1;
        .block {
            .title {
                opacity: 1;
                transform: translate(0, 0);
            }
            .sup {
                opacity: 1;
                transform: translate(0, 0);
            }
        }
    }
    .block {
        position: absolute;
        text-align: center;
        display: flex;
        flex-direction: column;
        padding: 0 20px;
        width: 100%;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        line-height: 1.2;
        .title {
            font-size: calc(30px + 10*(100vw - 320px)/960);
            font-weight: normal;
            line-height: 1.2;
            color: white;
            opacity: 0;
            transform: translate(0, 40px);
            transition: opacity 1s ease-out .5s, transform 1s ease-out .5s;
        }
        .sup {
            font-size: calc(16px + 6*(100vw - 320px)/960);
            line-height: 1.2;
            color: white;
            margin: 20px 0 0;
            padding: 20px 0 0;
            position: relative;
            opacity: 0;
            transform: translate(0, 40px);
            transition: opacity 1s ease-out 1s, transform 1s ease-out 1s;
            &::before {
                display: block;
                content: '';
                width: 60px;
                height: 1px;
                background-color: $thin;
                top: 0;
                left: 0;
                left: 50%;
                position: absolute;
                transform: translate(-50%, 0);
            }
        }
    }
    &.block-reverse {
        flex-direction: column;
        .sup {
            margin: 0 0 10px;
        }
    }
}

.container {
    width: 100%;
    background-color: $thin;
    padding: 30px 0 60px;
    @include media(md) {
        padding: 60px 0 120px;
    }
}

// front-page

.wf-active {
    .title-top span {
        font-family: $font-en;
    }
}
.title-top {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.0;
    span {
        display: block;
        font-family: $font-load;
        font-size: calc(40px + 40*(100vw - 320px)/960);
        font-weight: 400;
        line-height: 1.0;
    }
}

.wf-active {
    .sec-top-aboutus .title {
        font-family: $font-min;
    }
}
.sec-top-aboutus {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    .title {
        font-size: calc(20px + 10*(100vw - 320px)/960);
        font-family: $font-load;
    }
}

.wf-active {
    .sec-top-service article .box .title {
        font-family: $font-min;
    }
    .sec-top-service article .box .title span {
        font-family: $font-en;
    }
}
.sec-top-service {
    width: 100%;
    @include media(xl) {
        max-width: 1400px;
        margin-right: auto;
        margin-left: auto;
    }
    article {
        position: relative;
        width: 100%;
        padding: 20px;
        @include media(lg) {
            padding: 80px;
        }
        &.reverse {
            .box {
                width: 100%;
                @include media(lg) {
                    width: 50%;
                    background: linear-gradient(90deg, $thin, white);
                    padding: 80px;
                    margin-left: auto;
                    margin-right: inherit;
                }
                .inner {
                    width: 100%;
                }
            }
            figure {
                width: 100%;
                 @include media(lg) {
                    top: 0;
                    left: 0;
                    right: inherit;
                    width: 50%;
                    margin-right: auto;
                }
            }
        }
        .box {
            width: 100%;
            margin: 20px 0 0;
            @include media(lg) {
                width: 50%;
                background: linear-gradient(90deg, white, $thin);
                padding: 80px;
                margin-right: auto;
            }
            .inner {
                width: 100%;
            }
            .title {
                font-size: calc(20px + 10*(100vw - 320px)/960);
                font-family: $font-load;
                span {
                    display: block;
                    font-family: $font-load;
                    color: $key;
                    font-weight: 400;
                    font-size: 1rem;
                }
            }
        }
        figure {
            width: 100%;
             @include media(lg) {
                position: absolute;
                top: 0;
                right: 0;
                width: 50%;
                margin-left: auto;
            }
        }
        .list-link {
            font-size: 1.25rem;
        }
   }

}

.sec-top-info {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    @include media(xl) {
        max-width: 1200px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
    }
}
.sec-top-news {
    width: 100%;
    @include media(xl) {
        width: calc(100% - 338px);
        flex: 0 0 calc(100% - 338px);
    }
}
.news-list-top {
    margin: 30px 0 0;
    dl {
        width: 100%;
        background-color: white;
        border-bottom: 1px solid $gray;
        @include media(md) {
            display: flex;
            flex-flow: row wrap;
        }
    }
    dt {
        padding: 20px 20px 0;
        @include media(md) {
            padding: 20px;
            flex: 0 0 130px;
            width: 130px;
        }
    }
    dd {
        padding: 10px 20px 20px;
        @include media(md) {
            padding: 20px;
            flex: 0 0 calc(100% - 130px);
            width: calc(100% - 130px);
        }
    }
}

.sec-top-sns {
    margin-top: 40px;
    @include media(xl) {
        margin-top: 0;
    }
}





// 下層

.wf-active {
    .sec-tocontact .title span {
        font-family: $font-en;
    }
}
.sec-tocontact {
    .title {
        font-size: 1rem;
        text-align: center;
        font-weight: 700;
        line-height: 1.0;
        span {
            display: block;
            font-family: $font-load;
            font-size: calc(40px + 40*(100vw - 320px)/960);
            font-weight: 400;
            line-height: 1.0;
        }
    }
}

/* 会社情報 */
.sec-profile {
    th {
        width: 100%;
        @include media(sm) {
            width: 200px;
        }
    }
    .group {
        figure {
            text-align: left;
            img {
                width: 200px;
                height: auto;
            }
        }
    }
}
.sec-map {
    iframe {
        width: 100%;
        height: 320px;
    }
}

/* 事業紹介 */
.list-service {
    margin: 0 0 -40px;
    @include media(md) {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }
    & > li {
        padding: 0 0 40px;
        width: 100%;
        @include media(md) {
            width: 48%;
            flex: 0 0 48%;
        }
    }
    a {
        &:hover {
            .title {
                color: $link;
            }
        }
    }
    .title {
        margin: 10px 0 0;
        color: $black;
    }
}

/* 職業紹介事業 */
.wf-active {
    .list-flow li .title span,
    .list-flow li .tel {
        font-family: $font-en;
    }
}
.list-flow {
    li {
        padding: 20px;
        background: linear-gradient(180deg, white, $thin);
        position: relative;
        @include media(md) {
            padding: 30px 30px 30px 100px;
        }
        &:not(:last-child) {
            margin: 0 0 30px;
            &:before {
                content: "";
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translate(-50%, 0);
                border: 30px solid transparent;
                border-top: 30px solid $thin;
            }
        }
        .title {
            font-size: 1.25rem;
            font-weight: 700;
            color: $main;
            span {
                display: inline-block;
                color: $main;
                font-size: 1.25rem;
                font-weight: 400;
                margin: 0 10px 0 0;
                @include media(md) {
                    line-height: 1.0;
                    position: absolute;
                    font-size: calc(20px + 60*(100vw - 320px)/960);
                    top: 30px;
                    left: 30px;
                    margin: 0;
                }
            }
        }
        .tel {
            font-family: $font-load;
            font-size: calc(20px + 20*(100vw - 320px)/960);
            font-weight: 700;
            color: $main;
            span {
                font-size: 1rem;
                display: inline-block;
                margin: 0 10px 0 0;
            }
        }
    }
}

/* 登録支援機関 */
.dl-time {
    display: flex;
    flex-flow: row wrap;
    dt {
        flex: 0 0 160px;
        width: 160px;
    }
    dd {
        flex: 0 0 calc(100% - 160px);
        width: calc(100% - 160px);
    }
}

/* お知らせ一覧 */
.news-list {
    margin: 30px 0 0;
    dl {
        width: 100%;
        background-color: white;
        border-bottom: 1px solid $thin;
        @include media(md) {
            display: flex;
            flex-flow: row wrap;
        }
    }
    dt {
        padding: 20px 20px 0;
        @include media(md) {
            padding: 20px 0;
            flex: 0 0 130px;
            width: 130px;
        }
    }
    dd {
        padding: 10px 20px 20px;
        @include media(md) {
            padding: 20px 0 20px 20px;
            flex: 0 0 calc(100% - 130px);
            width: calc(100% - 130px);
        }
    }
}

/* お知らせ */
.post-top {
    padding: 0 0 30px;
    margin: 0 0 30px;
    border-bottom: 1px solid $pale;
    .title-main {
        text-align: center;
    }
    .date {
        text-align: center;
    }
}

/* サイトマップ */
.list-sitemap {
    border-top: 1px solid $pale;
    width: 100%;
    li {
        border-bottom: 1px solid $pale;
        padding: 20px 0;
    }
    .lv2 {
        padding: 20px 0 0 40px;
        li {
            &:last-child {
                border-bottom: none;
            }
        }
    }
    a {
        color: $black;
        &:hover {
            color: $link;
        }
    }
}












// ----------------------------------------
// フッター
// ----------------------------------------

.wf-active {
    .footer {
        &-foot {
            .copyright {
                font-family: $font-en;
            }
        }
    }
}
.footer {
    position: relative;
    padding: 30px 20px 60px;
    @include media(md) {
        padding: 70px 20px 120px;
    }
    &::before {
        position: absolute;
        width: 100%;
        height: 10px;
        background: linear-gradient(90deg, $key, $main);
        top: 0;
        left: 0;
        content: '';
        display: block;
    }
    &-inner {
        max-width: 960px;
        margin-right: auto;
        margin-left: auto;
    }
    &-body {
        @include media(md) {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
        }
    }
    &-foot {
        .copyright {
            margin: 40px 0 0;
            text-align: right;
            font-size: .875rem;
            line-height: 1.0;
            font-family: $font-load;
        }
    }
    &-logo {
        .logo {
            line-height: 1.0;
            svg {
                width: 214px;
                height: 50px;
                @include media(md) {
                    width: 347px;
                    height: 81px;
                }
                .st0 {
                    fill: $main;
                }
                .st1 {
                    fill: $key;
                }
            }
        }
    }
    &-sns {
        padding: 0 0 0 20px;
        text-align: right;
        a {
            display: inline-block;
            border-radius: 100px;
            background-color: white;
            padding: 10px;
            line-height: 1.0;
            &:hover {
                color: white;
            }
            svg {
                width: 20px;
                height: 20px;
                fill: currentColor;
            }
        }
    }
    &-address {
        margin: 20px 0 0;
        width: 100%;
        @include media(md) {
            margin: 0;
            width: 40%;
        }
        .address-list {
            li {
                font-size: 0.875rem;
                line-height: 1.5;
                color: white;
                &:not(:first-child) {
                    margin: 20px 0 0;
                }
                a {
                    font-weight: bold;
                    color: white;
                    &:hover {
                    }
                }
                span {
                    white-space: nowrap;
                }
            }
        }
    }
    &-info {
        p {
            line-height: 1.8;
        }
    }
    &-link {
        margin: 20px 0 0;
        width: 100%;
        @include media(md) {
            margin: 0;
            width: 50%;
        }
        .link-list {
            @include media(md) {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
            }
            div {
                width: 100%;
                margin: 0 0 10px;
                & > p {
                    font-size: 1rem;
                    line-height: 1.5;
                    color: $black;
                    @include media(md) {
                        font-size: 0.75rem;
                    }
                }
                .list-child-lv2 {
                    li {
                        line-height: 1.5;
                        color: $black;
                        margin: 10px 0 0;
                    }
                }
            }
            .separate {
                margin: 10px 0 0;
                @include media(md) {
                    margin: 30px 0 0;
                }
            }
            a {
                display: inline-block;
                position: relative;
                color: $black;
                padding: 0 0 0 10px;
                &:hover {
                    &::before {
                    }
                }
                &::before {
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: 5px;
                    height: 5px;
                    transform: rotate(-45deg) translate(0, -50%);
                    border: 2px solid $black;
                    border-top-width: 0;
                    border-left-width: 0;
                    content: '';
                }
            }
        }
    }
    &-bnr {
        margin: 40px 0 0;
    }
}







// ----------------------------------------
// 特殊
// ----------------------------------------


// grobal nav
.is-animation #gnav {
    display: inline-block;
}
.gnav--burger {
    position: fixed;
    top: 40px;
    right: 20px;
    display: inline-block;
    white-space: nowrap;
    text-align: center;
    z-index: 1000;
    display: block;
    @include media(md) {
        top: 30px;
        right: 30px;
        display: none;
    }
    &.active {
        div {
            &:nth-of-type(2) {
                opacity: 0;
            }
        }
    }
    &-icon {
        position: relative;
        display: inline-block;
        width: 20px;
        height: 16px;
        div {
            position: absolute;
            display: inline-block;
            transition: transform .3s;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: $black;
            &:nth-of-type(1) {
                top: 0;
                -webkit-animation: burger-bar01 .75s forwards;
                animation: burger-bar01 .75s forwards;
            }
            &:nth-of-type(2) {
                top: 7px;
                opacity: 1;
            }
            &:nth-of-type(3) {
                bottom: 0;
                -webkit-animation: burger-bar03 .75s forwards;
                animation: burger-bar03 .75s forwards;
            }
        }
    }
    &-text {
        display: block;
        font-size: 0.75rem;
        color: $black;
        line-height: 1;
        font-weight: 400;
    }
}
@-webkit-keyframes burger-bar01 {
    0% { -webkit-transform: translateY(7px) rotate(45deg); }
    50% { -webkit-transform: translateY(7px) rotate(0); }
    100% { -webkit-transform: translateY(0) rotate(0); }
}
@keyframes burger-bar01 {
    0% { transform: translateY(7px) rotate(45deg); }
    50% { transform: translateY(7px) rotate(0); }
    100% { transform: translateY(0) rotate(0); }
}
@-webkit-keyframes burger-bar03 {
    0% { -webkit-transform: translateY(-7px) rotate(-45deg); }
    50% { -webkit-transform: translateY(-7px) rotate(0); }
    100% { -webkit-transform: translateY(0) rotate(0); }
}
@keyframes burger-bar03 {
    0% { transform: translateY(-7px) rotate(-45deg); }
    50% { transform: translateY(-7px) rotate(0); }
    100% { transform: translateY(0) rotate(0); }
}


// drawer
.scroll-prevent {
  position: fixed;
  z-index: -1;
  width: 100%;
  height: 100%;
}
.drawermenu {
    z-index: -1;
    position: fixed;
    top: 0;
    right: 0;
    width: 85vw;
    height: 100%;
    overflow: auto;
    background-color: $thin;
    transition: opacity .3s ease-out;
    padding: 30px calc(20px + 20*(100vw - 320px)/1280) 30px calc(20px + 20*(100vw - 320px)/960);
    opacity: 0;
    -webkit-overflow-scrolling: touch;
    text-align: right;
    animation: drawermenu 1s cubic-bezier(0.65, 0.05, 0.36, 1) forwards;
    @include media(sm) {
        width: 60vw;
    }
    @include media(md) {
        width: 40vw;
    }
    &.hide {
        opacity: 0;
    }
    &.is-active {
        opacity: 1;
        z-index: 2000;
    }
    .drawermenu--burger-icon {
        cursor: pointer;
        position: relative;
        display: inline-block;
        width: 30px;
        height: 30px;
        div {
            position: absolute;
            display: inline-block;
            transition: transform .3s;
            left: 0;
            width: 20px;
            height: 2px;
            background-color: $black;
            &:nth-of-type(1) {
                top: 50%;
                transform: rotate(45deg) translate(-50%, -50%);
                -webkit-animation: drawer-bar01 .75s forwards;
                animation: drawer-bar01 .75s forwards;
            }
            &:nth-of-type(2) {
                opacity: 0;
            }
            &:nth-of-type(3) {
                top: 50%;
                transform: rotate(-45deg) translate(-50%, -50%);
                -webkit-animation: drawer-bar03 .75s forwards;
                animation: drawer-bar03 .75s forwards;
            }
        }
    }
}
@-webkit-keyframes drawer-bar01 {
    0% { -webkit-transform: translateY(0) rotate(0); }
    50% { -webkit-transform: translateY(0) rotate(0); }
    100% { -webkit-transform: translateY(0) rotate(45deg); }
}
@keyframes drawer-bar01 {
    0% { transform: translateY(0) rotate(0); }
    50% { transform: translateY(0) rotate(0); }
    100% { transform: translateY(0) rotate(45deg); }
}
@-webkit-keyframes drawer-bar03 {
    0% { -webkit-transform: translateY(0) rotate(0); }
    50% { -webkit-transform: translateY(0) rotate(0); }
    100% { -webkit-transform: translateY(0) rotate(-45deg); }
}
@keyframes drawer-bar03 {
    0% { transform: translateY(0) rotate(0); }
    50% { transform: translateY(0) rotate(0); }
    100% { transform: translateY(0) rotate(-45deg); }
}


.drawer-nav--section .tel {
    font-family: $font-en;
}


.drawer-nav {
    &--section {
        border-top: 1px solid $pale;
        padding-top: 20px;
        text-align: left;
        &-first {
            border-top: none;
            padding-top: 0;
        }
        .address {
            font-size: 0.875rem;
            line-height: 1.4;
            color: $aux;
            span {
                font-size: 0.75rem;
                display: block;
                color: $aux;
            }
        }
        .tel {
            font-size: calc(20px + 10*(100vw - 320px)/960);
            font-weight: 700;
            line-height: 1.3;
            a {
                color: $link;
            }
            span {
                display: block;
                font-size: 0.875rem;
                font-weight: 500;
            }
        }
    }
    &--title {
        font-size: calc(14px + 4*(100vw - 320px)/960);
        margin-bottom: 10px;
        a {
            color: $black;
            transition: color .3s ease-in-out;
            &:hover {
                color: $link;
            }
        }
    }
    &--list {}
    &--item {
        line-height: 1.4;
        &:not(:first-child){
            margin-top: 10px;
        }
        a {
            color: $black;
            font-size: calc(14px + 4*(100vw - 320px)/960);
            transition: color .3s ease-in-out;
            &:hover {
                color: $link;
            }
            svg {
                height: 12px;
                width: auto;
                margin: 0 0 0 5px;
            }
        }
        .toggle {
            cursor: pointer;
            &:hover {
                span {
                    color: $link;
                    &:after {
                        border-top: 4px solid $link;
                    }
                }
            }
            span {
                display: inline-block;
                font-size: calc(14px + 4*(100vw - 320px)/960);
                line-height: 1.3;
                color: $black;
                transition: color .3s ease-in-out;
                &:after {
                    display: inline-block;
                    content: '';
                    width: 4px;
                    height: 4px;
                    border: 4px solid transparent;
                    border-top: 4px solid $black;
                    margin-left: 5px;
                    vertical-align: middle;
                    transition: border-top .3s ease-in-out;
                }
            }
            &.active {
                span {
                    color: $link;
                    &:after {
                        border-bottom: 4px solid $link;
                        border-top: none;
                    }
                }
            }
        }
        .child {
            padding-left: calc(10px + 20*(100vw - 320px)/960);
            margin: 0 0 10px;
            line-height: 1.3;
            &.hide {
                display: none;
            }
            &--item {
                margin-top: 10px;
            }
        }
    }
}


.page-cover {
    display: none;
    &.is-active {
        display: block;
        z-index: 1900;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.6);
    }
}



// slick

.slick-dots {
    bottom: 10vh;
    padding: 0 10%;
    display: flex;
    justify-content: space-between;
    li {
        width: 40%;
        height: 6px;
        @include media(md) {
            height: 3px;
        }
        &.slick-active {
            background-color: #fff;
            button:before {
                opacity: 1;
                left: 0;
                -webkit-animation: slick-bar 5s linear;
                animation: slick-bar 5s linear;
                background-color: $key;
            }
        }
        button:before {
            content: '';
            background-color: $black;
            opacity: 0.4;
            width: 100%;
            height: 6px;
            @include media(md) {
                height: 3px;
            }
        }
    }
}
@-webkit-keyframes slick-bar {
    0% { width: 0; }
    100% { width: 100%; }
}
@keyframes slick-bar {
    0% { width: 0; }
    100% { width: 100%; }
}

.slider:hover {
    .slick-next {
        right: 3%;
    }
    .slick-prev {
        left: 3%;
    }
}
.slick-next {
    right: -50px;
    transition: right .3s ease-in-out;
    &:hover:before {
        opacity: 1;
    }
}
.slick-prev {
    left: -50px;
    transition: left .3s ease-in-out;
    z-index: 1;
    &:hover:before {
        opacity: 1;
    }
}
.slick-prev:before,
.slick-next:before {
    font-size: calc(20px + 20*(100vw - 320px)/960);
    opacity: .6;
    transition: opacity .3s ease-out;
    text-shadow: $aux 0 0 10px;
}

.nowrap {
  white-space: nowrap;
}

.kakomi{
   border-radius: 10px;
   border:solid 1px #ccc;
   padding:10px;
   margin-bottom:10px;
}

.sub-item{
   padding-left:40px;
}

.verticallist .wpcf7-list-item{
 display:block;
}

.box33 {
	width:33%;
	float:left;
}
.box66 {
	width:66%;
	float:left;
}


/*
.loadingEffect {
    display: block;
    z-index: 100000;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
}

.loadingEffect::before, .loadingEffect::after {
    display: block;
    z-index: 99;
    position: fixed;
    right: 0;
    left: 0;
    width: 100%;
    height: 50%;
    margin-right: auto;
    margin-left: auto;
    background-color: #F5F5F5;
    line-height: 1;
    content: ' ';
    transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
    transition-delay: .3s;
}

.loadingEffect::before {
    top: 0;
}

.loadingEffect::after {
    bottom: 0;
}

.loaded .loadingEffect::before {
    height: 0;
    transform: translateY(-1%);
}

.loaded .loadingEffect::after {
    height: 0;
    transform: translateY(1%);
}
*/

.totel {
  color: #008D30;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "ヒラギノ角ゴシック Pro", "hiragino kaku gothic pro", meiryo, "メイリオ", sans-serif;
  font-size: calc(20px + 20*(100vw - 320px)/960);
  font-weight: 700;
}

.totel span {
  display: inline-block;
  margin: 0 10px 0 0;
  font-size: 1rem;
}

.news-list-top img{
	padding:5px;
}

.sec-overseas{
   ul{
      list-style-type: square!important;
      margin-left:20px;
   }
}