@charset "UTF-8";
/*
Version: 1.0
Author: グランソールジャパン
Author URI: grandsoul-japan.co.jp
 */
/* Box sizing rules */
/* Box sizingの定義 */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
/* デフォルトのpaddingを削除 */
ul,
ol {
  padding: 0;
  margin: 0;
}

/* Remove default margin */
/* デフォルトのmarginを削除 */
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core body defaults */
/* bodyのデフォルトを定義 */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Remove list styles on ul, ol elements with a class attribute */
/* class属性を持つul、ol要素のリストスタイルを削除 */
ul,
ol {
  list-style: none;
}

/* A elements that don't have a class get default styles */
/* classを持たない要素はデフォルトのスタイルを取得 */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
/* img要素の扱いを簡単にする */
img {
  max-width: 100%;
}

/* Inherit fonts for inputs and buttons */
/* inputやbuttonなどのフォントは継承を定義 */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
/* 見たくない人用に、すべてのアニメーションとトランジションを削除 */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./assets/images/ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./assets/fonts/slick.eot");
  src: url("./assets/fonts/slick.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/slick.woff") format("woff"), url("./assets/fonts/slick.ttf") format("truetype"), url("./assets/fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 50px;
  width: 50px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}

.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

/* 標準色 */
/* ベースカラー */
/* 補助色 */
/* 線など */
/* 薄い背景 */
/* オリジナル */
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
}

.wf-active body {
  font-family: "Noto Sans JP", meiryo, "メイリオ", sans-serif;
}

body {
  color: #231815;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "ヒラギノ角ゴシック Pro", "hiragino kaku gothic pro", meiryo, "メイリオ", sans-serif;
  line-height: 1.4;
  font-weight: 400;
  width: 100%;
  height: 100%;
}

/*ie7*/
*:first-child + html body {
  font-size: 75%;
}

/*ie5-6*/
* html body {
  font-size: 75%;
}

a,
area,
button,
[role="button"],
input:not([type="range"]),
label,
select,
summary,
textarea {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

a:hover img {
  opacity: .8;
}

figure {
  text-align: center;
}

figure figcaption {
  font-size: calc(12px + 2*(100vw - 320px)/1280);
  color: #767676;
  text-align: center;
  margin: 5px 0 0;
}

a {
  color: #ED4E84;
  text-decoration: none;
  backface-visibility: hidden;
}

a:hover {
  color: #930f3c;
}

a.line {
  text-decoration: underline;
}

a.line:hover {
  text-decoration: none;
}

input[type="submit"] {
  background: none;
  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
  font-size: 100%;
  cursor: pointer;
}

input[type="submit"]::-webkit-search-decoration {
  display: none;
}

input[type="submit"]::focus {
  outline-offset: -2px;
}

input[type="text"],
input[type="tel"],
input[type="email"],
select.full-width,
textarea {
  border: 1px solid #dedede;
}

input[type="text"].full-width,
input[type="tel"].full-width,
input[type="email"].full-width,
select.full-width,
textarea.full-width {
  width: 100%;
}

.slider {
  opacity: 0;
  transition: 3s;
}

.slider.slick-initialized {
  opacity: 1;
}

.breadcrumb {
  background-color: white;
  width: 100%;
  display: block;
  color: #231815;
  font-size: 0.875rem;
  line-height: 1.3;
  padding: 10px;
}

@media only screen and (min-width: 768px) {
  .breadcrumb {
    padding: 20px;
  }
}

.breadcrumb .home,
.breadcrumb .post,
.breadcrumb .category {
  padding: 0 5px;
  display: inline;
  vertical-align: middle;
  font-size: 0.875rem;
  line-height: 1.3;
  color: #231815;
  transition: color .3s ease-in-out;
}

.breadcrumb .home:hover,
.breadcrumb .post:hover,
.breadcrumb .category:hover {
  color: #ED4E84;
}

.breadcrumb .home span.current-item,
.breadcrumb .post span.current-item,
.breadcrumb .category span.current-item {
  color: #231815;
}

.breadcrumb .current-item {
  color: #231815;
}

.breadcrumb .current-item:hover {
  color: #231815;
}

/* オリジナル
--------------------------------------------- */
.block-post {
  max-width: 960px;
  padding: 0 5%;
  margin-right: auto;
  margin-left: auto;
}

.block-post--wrap {
  overflow-x: hidden;
}

.block-post p {
  text-align: justify;
}

.block-post h1 + p, .block-post h2 + p {
  margin-top: 30px;
}

.block-post h3 + p, .block-post h4 + p {
  margin-top: 20px;
}

.block-post .wp-block-image + p, .block-post p + .wp-block-image {
  margin-top: calc(40px + 40*(100vw - 320px)/1280);
}

.block-post p + p {
  margin-top: calc(30px + 30*(100vw - 320px)/1280);
}

/* contact form 7
--------------------------------------------- */
input[type="submit"].wpcf7-submit {
  display: inline-block;
  padding: 10px 20px;
  min-width: 240px;
  position: relative;
  border-radius: 100px;
  border: 2px solid #008D30;
  background-color: #008D30;
  color: white;
  text-align: center;
  line-height: 1.3;
}

/* WordPress Native Styles
--------------------------------------------- */
.aligncenter {
  display: block;
  margin: 0 auto;
}

.alignright {
  float: right;
}

.alignleft {
  float: left;
}

img[class*='wp-image-'],
img[class*='attachment-'] {
  max-width: 100%;
  height: auto;
}

.wp-caption {
  max-width: 96%;
  padding: 8px;
}

.wp-caption.alignnone {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
  margin: 5px 0 20px 20px;
}

.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  padding: 0;
  width: auto;
  max-width: 100%;
}

.wp-caption p.wp-caption-text {
  margin: 0;
  padding: 5px 0;
  font-size: 1.4rem;
}

.postContent h2 {
  font-size: 2.2rem;
}

.postContent h3 {
  font-size: 2.0rem;
}

.postContent h4 {
  font-size: 1.8rem;
}

.postContent h5 {
  font-size: 1.6rem;
}

.postContent h6 {
  font-size: 1.4rem;
}

.postContent p {
  margin-bottom: 10px;
}

.postContent pre {
  background-color: #F7F5EE;
  padding: 8px;
  margin: 16px 0;
  font-size: 1.4rem;
}

.postContent strong {
  font-weight: bold;
}

.postContent em {
  font-style: italic;
}

.postContent ul {
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 20px;
}

.postContent ol {
  list-style-type: decimal;
  padding-left: 20px;
  margin-bottom: 20px;
}

.postContent blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  border-left: 7px solid #ccc;
}

.postContent hr {
  border-top: 1px solid #ccc;
  margin: 20px 5px;
}

.postContent .aligncenter,
.postContent div.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.postContent .alignleft {
  float: left;
  margin-right: 10px;
}

.postContent .alignright {
  float: right;
}

.postContent .wp-caption-text {
  font-size: 1.2rem;
}

.sec {
  max-width: 960px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 5%;
  padding-right: 5%;
}

@media only screen and (min-width: 1280px) {
  .sec {
    padding-left: 0;
    padding-right: 0;
  }
}

.sec.bg-white {
  padding: 30px 20px;
}

@media only screen and (min-width: 768px) {
  .sec.bg-white {
    padding: 60px;
  }
}

.sec.bg-thin {
  padding: 30px 20px;
  background-color: #F7F5EE;
}

@media only screen and (min-width: 768px) {
  .sec.bg-thin {
    padding: 60px;
  }
}

.sec .bg-white {
  padding: 30px 20px;
}

@media only screen and (min-width: 768px) {
  .sec .bg-white {
    padding: 60px;
  }
}

.sec .bg-thin {
  padding: 30px 20px;
  background-color: #F7F5EE;
}

@media only screen and (min-width: 768px) {
  .sec .bg-thin {
    padding: 60px;
  }
}

.sec .wrapper {
  background-color: white;
  max-width: 960px;
  margin-right: auto;
  margin-left: auto;
}

.sec .wrapper .inner {
  padding: 20px 5%;
}

@media only screen and (min-width: 768px) {
  .sec .wrapper .inner {
    padding: 60px 80px;
  }
}

.sec .inner {
  max-width: 630px;
  margin-right: auto;
  margin-left: auto;
}

.sec .inner-wide {
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
}

.sec p {
  text-align: justify;
  line-height: 1.8;
}

.sec p + p {
  margin-top: 20px;
}

.sec h1 + p, .sec h2 + p, .sec h1 + figure, .sec h2 + figure, .sec h1 + ul, .sec h1 + ol, .sec h2 + ul, .sec h2 + ol {
  margin-top: 30px;
}

.sec h3 + p, .sec h4 + p, .sec h3 + figure, .sec h4 + figure, .sec h3 + ul, .sec h3 + ol, .sec h4 + ul, .sec h4 + ol {
  margin-top: 20px;
}

.sec figure + p, .sec p + figure {
  margin-top: calc(20px + 20*(100vw - 320px)/960);
}

.sec strong {
  color: #008D30;
  font-weight: bold;
  font-style: normal;
}

.sec em {
  color: #008D30;
  font-weight: bold;
  font-style: normal;
}

.sec b {
  font-weight: bold;
  font-style: normal;
}

.sec li {
  line-height: 1.8;
}

@media only screen and (min-width: 768px) {
  .sec-head-col {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }
}

.sec-head-col .title-row {
  margin: 0 40px 0 0;
}

.sec-head-col .lead {
  margin: 10px 0 0;
}

@media only screen and (min-width: 768px) {
  .sec-head-col .lead {
    margin: 0;
  }
}

.sec-main {
  max-width: 960px;
  padding: 0 5%;
  margin-right: auto;
  margin-left: auto;
}

@media only screen and (min-width: 1280px) {
  .sec-main {
    padding: 0;
  }
}

.sec-clip {
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  padding: 120px 10%;
}

.sec-clip-inner {
  background-color: white;
  padding: 80px 5%;
  border: 10px solid rgba(255, 255, 255, 0.3);
  background-clip: padding-box;
}

.sec-clip hgroup {
  background-clip: text;
  -webkit-background-clip: text;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
}

.sec-clip .number {
  color: rgba(0, 0, 0, 0.4);
  font-size: calc(18px + 22*(100vw - 320px)/960);
  font-weight: black;
  line-height: 1.3;
}

.sec-clip .title span {
  color: rgba(0, 0, 0, 0.4);
  font-size: calc(18px + 22*(100vw - 320px)/960);
  font-weight: black;
  line-height: 1.3;
}

.sec-bg {
  background-color: #F7F5EE;
  padding: calc(80px + 40*(100vw - 320px)/960) 5%;
}

.block-full-bg .pic {
  position: relative;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .block-full-bg .pic {
    min-height: calc(320px + 360*(100vw - 320px)/960);
    max-height: calc(320px + 360*(100vw - 320px)/960);
  }
}

.block-full-bg .pic:before {
  display: block;
  padding-top: 100%;
  content: '';
}

.block-full-bg .pic img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.block-half {
  display: block;
}

@media only screen and (min-width: 768px) {
  .block-half {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
  }
}

@media only screen and (min-width: 768px) {
  .block-half.reverse {
    flex-flow: row-reverse nowrap;
  }
}

.block-half > .pic {
  width: 100%;
  min-width: 0;
}

@media only screen and (min-width: 768px) {
  .block-half > .pic {
    width: 50%;
    flex: 0 0 50%;
  }
}

.block-half > .detail {
  padding: 20px 0;
  min-width: 0;
}

@media only screen and (min-width: 768px) {
  .block-half > .detail {
    padding: 0;
    width: 45%;
    flex: 0 0 45%;
  }
}

.block-half-bg {
  display: block;
}

@media only screen and (min-width: 768px) {
  .block-half-bg {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }
}

@media only screen and (min-width: 768px) {
  .block-half-bg.reverse {
    flex-flow: row-reverse nowrap;
  }
}

.block-half-bg > .pic {
  position: relative;
  width: 100%;
  min-width: 0;
}

@media only screen and (min-width: 768px) {
  .block-half-bg > .pic {
    width: 50%;
    flex: 1 0 50%;
    min-height: calc(320px + 360*(100vw - 320px)/960);
    max-height: calc(320px + 360*(100vw - 320px)/960);
  }
}

.block-half-bg > .pic:before {
  display: block;
  padding-top: 100%;
  content: '';
}

.block-half-bg > .pic img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.block-half-bg > .detail {
  padding: calc(20px + 20*(100vw - 320px)/960) 5%;
  min-width: 0;
}

@media only screen and (min-width: 768px) {
  .block-half-bg > .detail {
    width: 50%;
    flex: 1 1 50%;
  }
}

.block-half-nr {
  display: block;
}

@media only screen and (min-width: 768px) {
  .block-half-nr {
    display: flex;
    flex-flow: row nowrap;
    align-items: top;
    justify-content: space-between;
  }
}

@media only screen and (min-width: 768px) {
  .block-half-nr.reverse {
    flex-flow: row-reverse nowrap;
  }
}

.block-half-nr > .pic {
  min-width: 0;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .block-half-nr > .pic {
    width: 47.5%;
    flex: 0 0 47.5%;
  }
}

.block-half-nr > .detail {
  margin-top: 30px;
  min-width: 0;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .block-half-nr > .detail {
    margin: 0;
    width: 47.5%;
    flex: 0 0 47.5%;
  }
}

.block-memo {
  color: #767676;
}

.block-lead {
  text-align: center;
}

.block-basic {
  max-width: 90%;
  margin-right: auto;
  margin-left: auto;
}

.block-bg {
  background-color: #F7F5EE;
  padding: calc(20px + 20*(100vw - 320px)/960);
}

.block-bg.white {
  background-color: white;
}

.block-bg-md {
  padding: calc(20px + 40*(100vw - 320px)/960);
}

.block-bg-lg {
  padding: calc(20px + 70*(100vw - 320px)/960) calc(20px + 40*(100vw - 320px)/960);
}

.block-border {
  border: 5px solid #dedede;
  padding: calc(10px + 30*(100vw - 320px)/960);
}

.block-map {
  width: 100%;
  height: calc(480px + 200*(100vw - 320px)/960);
}

.block-2c .row {
  margin: -40px 0 0 0;
}

@media only screen and (min-width: 480px) {
  .block-2c .row {
    display: flex;
    flex-flow: row wrap;
    margin: -60px 0 0 -40px;
  }
}

.block-2c .col {
  padding: 40px 0 0 0;
}

@media only screen and (min-width: 480px) {
  .block-2c .col {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 60px 0 0 40px;
  }
}

.block-2c .caption {
  margin-top: 20px;
}

.block-2c .caption .title {
  font-size: 1.0rem;
  line-height: 1.3;
  font-weight: normal;
  color: #231815;
}

.block-2c .caption .title span {
  font-weight: bold;
  display: block;
}

.block-2c a:hover .title {
  color: #ED4E84;
}

.block-2c .title {
  font-size: calc(20px + 4*(100vw - 320px)/960);
  line-height: 1.3;
  color: #231815;
  font-weight: 700;
  margin-top: 20px;
  transition: color .3s;
}

.block-2c .text {
  font-size: calc(14px + 2*(100vw - 320px)/960);
  text-align: justify;
  margin-top: 10px;
}

.block-3c .row {
  margin: -40px 0 0 0;
}

@media only screen and (min-width: 480px) {
  .block-3c .row {
    display: flex;
    flex-flow: row wrap;
    margin: -60px 0 0 -20px;
  }
}

.block-3c .col {
  padding: 40px 0 0 0;
}

@media only screen and (min-width: 480px) {
  .block-3c .col {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
    padding: 60px 0 0 20px;
  }
}

.block-3c .caption {
  margin-top: 20px;
}

.block-3c .caption .title {
  font-size: 1.0rem;
  line-height: 1.3;
  font-weight: normal;
  color: #231815;
}

.block-3c .caption .title span {
  font-weight: bold;
  display: block;
}

.block-3c a:hover .title {
  color: #ED4E84;
}

.block-3c .title {
  font-size: calc(20px + 4*(100vw - 320px)/960);
  line-height: 1.3;
  color: #231815;
  font-weight: 700;
  margin-top: 20px;
  transition: color .3s;
}

.block-3c .text {
  font-size: calc(14px + 2*(100vw - 320px)/960);
  text-align: justify;
  margin-top: 10px;
}

.block-4c .row {
  margin: -40px 0 0 0;
}

@media only screen and (min-width: 480px) {
  .block-4c .row {
    display: flex;
    flex-flow: row wrap;
    margin: -40px 0 0 -40px;
  }
}

.block-4c .col {
  padding: 40px 0 0;
}

@media only screen and (min-width: 480px) {
  .block-4c .col {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 40px 0 0 40px;
  }
}

@media only screen and (min-width: 768px) {
  .block-4c .col {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

.block-4c .caption {
  margin-top: 20px;
}

.block-4c .caption .title {
  font-size: 1.0rem;
  line-height: 1.3;
  font-weight: normal;
  color: #231815;
}

.block-4c .caption .title span {
  font-weight: bold;
  display: block;
}

.block-4c a:hover .title {
  color: #ED4E84;
}

.block-4c .title {
  font-size: calc(20px + 4*(100vw - 320px)/960);
  line-height: 1.3;
  color: #231815;
  font-weight: 700;
  margin-top: 20px;
  transition: color .3s;
}

.block-4c .text {
  font-size: calc(14px + 2*(100vw - 320px)/960);
  text-align: justify;
  margin-top: 10px;
}

.block-bnr-em--anchor {
  position: relative;
  width: 100%;
  background-size: cover;
  display: block;
}

.block-bnr-em--anchor .caption {
  padding-left: 10%;
  padding-right: 10%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}

.block-bnr-em--anchor .caption .title {
  font-size: 0.875rem;
  color: white;
  padding-bottom: 40px;
}

.block-bnr-em--anchor .caption .copy {
  font-size: 2.5rem;
  font-weight: normal;
  color: white;
  line-height: 1.3;
}

.grid {
  margin: 0 0 -30px -30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;
}

.grid.reverse {
  flex-direction: row-reverse;
}

@media only screen and (min-width: 480px) {
  .grid.sm-reverse {
    flex-direction: row-reverse;
  }
}

.grid.mt-m {
  margin-bottom: -60px;
}

.grid.nowrap {
  flex-wrap: nowrap;
}

.grid.jc-center {
  justify-content: center;
}

.grid.jc-end {
  justify-content: flex-end;
}

.grid.jc-between {
  justify-content: space-between;
}

.grid.jc-around {
  justify-content: space-around;
}

.grid.ai-start {
  align-items: flex-start;
}

.grid.ai-end {
  align-items: flex-end;
}

.grid.ai-center {
  align-items: center;
}

.grid.xs-3 .col {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
}

.grid.xs-2 .col {
  flex: 0 0 50%;
  max-width: 50%;
}

@media only screen and (min-width: 480px) {
  .grid.sm-4 .col {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media only screen and (min-width: 480px) {
  .grid.sm-3 .col {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }
}

@media only screen and (min-width: 480px) {
  .grid.sm-2 .col {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (min-width: 480px) {
  .grid.sm-2 .col {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (min-width: 768px) {
  .grid.md-5 .col {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media only screen and (min-width: 768px) {
  .grid.md-4 .col {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media only screen and (min-width: 768px) {
  .grid.md-3 .col {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }
}

@media only screen and (min-width: 768px) {
  .grid.md-2 .col {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (min-width: 768px) {
  .grid.md-2 .col {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (min-width: 1000px) {
  .grid.lg-4 .col {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media only screen and (min-width: 1000px) {
  .grid.lg-3 .col {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }
}

@media only screen and (min-width: 1000px) {
  .grid.lg-2 .col {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (min-width: 1000px) {
  .grid.lg-2 .col {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.grid.mt-m .col {
  padding-bottom: 60px;
}

.grid .col {
  padding: 0 0 30px calc(20px + 10*(100vw - 320px)/960);
  display: block;
  flex: 0 0 100%;
  max-width: 100%;
  min-width: 0;
}

@media only screen and (min-width: 480px) {
  .grid .col.sm-1-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (min-width: 480px) {
  .grid .col.sm-1-3 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }
}

@media only screen and (min-width: 480px) {
  .grid .col.sm-2-3 {
    flex: 0 0 66.6666%;
    max-width: 66.6666%;
  }
}

@media only screen and (min-width: 480px) {
  .grid .col.sm-1-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media only screen and (min-width: 480px) {
  .grid .col.sm-3-4 {
    flex: 0 0 75%;
    max-width: 75%;
  }
}

@media only screen and (min-width: 480px) {
  .grid .col.md-1-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (min-width: 768px) {
  .grid .col.md-1-3 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }
}

@media only screen and (min-width: 768px) {
  .grid .col.md-2-3 {
    flex: 0 0 66.6666%;
    max-width: 66.6666%;
  }
}

@media only screen and (min-width: 768px) {
  .grid .col.md-1-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media only screen and (min-width: 768px) {
  .grid .col.md-3-4 {
    flex: 0 0 75%;
    max-width: 75%;
  }
}

@media only screen and (min-width: 480px) {
  .grid .col.lg-1-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (min-width: 1000px) {
  .grid .col.lg-1-3 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }
}

@media only screen and (min-width: 1000px) {
  .grid .col.lg-2-3 {
    flex: 0 0 66.6666%;
    max-width: 66.6666%;
  }
}

@media only screen and (min-width: 1000px) {
  .grid .col.lg-1-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media only screen and (min-width: 1000px) {
  .grid .col.lg-3-4 {
    flex: 0 0 75%;
    max-width: 75%;
  }
}

a:hover .button {
  border: 1px solid #ED4E84;
  background-color: white;
  color: #ED4E84;
}

.button {
  display: inline-block;
  padding: 10px 20px;
  max-width: 240px;
  position: relative;
  border-radius: 100px;
  border: 2px solid #008D30;
  color: #008D30;
  text-align: center;
  line-height: 1.3;
}

.wf-active .btn a {
  font-family: "EB Garamond", serif;
}

.btn a {
  display: inline-block;
  padding: 20px;
  min-width: 240px;
  position: relative;
  background: linear-gradient(-135deg, #67AA1B, #008D30);
  color: white;
  text-align: center;
  line-height: 1.3;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "ヒラギノ角ゴシック Pro", "hiragino kaku gothic pro", meiryo, "メイリオ", sans-serif;
}

.btn a:hover {
  background: #008D30;
}

.btn a::after {
  display: inline-block;
  width: 6px;
  height: 6px;
  transform: rotate(-45deg);
  border: 2px solid white;
  border-top-width: 0;
  border-left-width: 0;
  content: '';
  margin: 0 0 2px 6px;
}

.btn-back a {
  display: inline-block;
  padding: 20px;
  min-width: 240px;
  position: relative;
  background: linear-gradient(-135deg, #008D30, #67AA1B);
  color: white;
  text-align: center;
  line-height: 1.3;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "ヒラギノ角ゴシック Pro", "hiragino kaku gothic pro", meiryo, "メイリオ", sans-serif;
}

.btn-back a:hover {
  background: #008D30;
}

.btn-back a::before {
  display: inline-block;
  width: 6px;
  height: 6px;
  transform: rotate(135deg);
  border: 2px solid white;
  border-top-width: 0;
  border-left-width: 0;
  content: '';
  margin: 0 6px 2px 0;
}

.btn-bit a {
  display: inline-block;
  padding: 0 10px 3px;
  border-radius: 100px;
  background: white;
  color: #231815;
  line-height: 1.3;
}

.btn-bit a:hover {
  color: #ED4E84;
}

.btn-bit a svg {
  margin: 0 0 -2px;
  width: 7px;
  height: 12px;
  fill: currentColor;
}

.wf-active .title-lead {
  font-family: "Yu Mincho", "YuMincho", "Noto Serif JP", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.title-lead {
  font-size: calc(20px + 10*(100vw - 320px)/960);
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "ヒラギノ角ゴシック Pro", "hiragino kaku gothic pro", meiryo, "メイリオ", sans-serif;
  text-align: center;
}

.title-main {
  font-size: calc(20px + 10*(100vw - 320px)/960);
  font-weight: 700;
  text-align: center;
}

.title-sub {
  font-size: calc(16px + 4*(100vw - 320px)/960);
  line-height: 1.5;
  font-weight: 700;
}

.title-xl {
  font-size: calc(30px + 10*(100vw - 320px)/960);
  line-height: 1.5;
  font-weight: 700;
}

.title-lg {
  font-size: calc(20px + 10*(100vw - 320px)/960);
  line-height: 1.5;
  font-weight: 700;
}

.title-lg span {
  display: block;
  font-size: 0.875rem;
  color: #67AA1B;
  margin-top: 10px;
}

.title-md {
  font-size: calc(18px + 2*(100vw - 320px)/960);
  line-height: 1.5;
  font-weight: 700;
}

.title-md span {
  display: block;
  font-size: 0.875rem;
  color: #008D30;
  margin-top: 10px;
}

.title-sm {
  font-size: 18px;
  line-height: 1.5;
  font-weight: 700;
}

.title-xs {
  font-size: 16px;
  line-height: 1.8;
  font-weight: 700;
}

@media only screen and (max-width: 479.98px) {
  .title-xs {
    line-height: 1.6;
  }
}

.title-line {
  position: relative;
  font-size: calc(20px + 20*(100vw - 320px)/960);
  line-height: 1.3;
  padding: 0 0 calc(20px + 20*(100vw - 320px)/960) 0;
  margin: 0 0 calc(20px + 10*(100vw - 320px)/960) 0;
  text-align: center;
}

.title-line span {
  display: block;
  font-size: 0.875rem;
  color: #008D30;
  margin-top: 10px;
}

.title-line::before {
  position: absolute;
  display: block;
  content: '';
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: calc(30px + 30*(100vw - 320px)/960);
  height: 2px;
  border-radius: 5px;
  background: #dedede;
}

.title-leftline {
  font-size: calc(20px + 10*(100vw - 320px)/960);
  line-height: 1.3;
  font-weight: 700;
  padding: 10px 0 10px 30px;
  border-left: 5px solid #008D30;
}

.title-underline {
  font-size: calc(20px + 10*(100vw - 320px)/960);
  line-height: 1.6;
  font-weight: 700;
  padding: 0 0 10px 0;
  border-bottom: 1px solid #231815;
}

@media only screen and (max-width: 479.98px) {
  .title-underline {
    line-height: 1.4;
  }
}

.title-square {
  position: relative;
  font-size: calc(20px + 20*(100vw - 320px)/960);
  line-height: 1.6;
  padding: calc(10px + 10*(100vw - 320px)/960) 0 calc(10px + 10*(100vw - 320px)/960) calc(30px + 30*(100vw - 320px)/960);
}

@media only screen and (max-width: 479.98px) {
  .title-square {
    line-height: 1.4;
  }
}

.title-square span {
  display: block;
  font-size: 1rem;
  color: #767676;
  margin-top: 10px;
}

.title-square::before {
  position: absolute;
  display: block;
  content: '';
  top: 0;
  left: 0;
  width: calc(10px + 10*(100vw - 320px)/960);
  height: 100%;
  border-radius: 5px;
}

.title-bg {
  width: 100%;
  vertical-align: middle;
  text-align: center;
  position: relative;
}

.title-bg:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  min-height: calc(300px + 100*(100vw - 320px)/960);
}

.title-bg .title {
  font-size: calc(18px + 22*(100vw - 320px)/960);
  text-align: center;
  color: white;
  font-weight: 400;
  letter-spacing: calc(5px + 5*(100vw - 320px)/960);
  vertical-align: middle;
  display: inline-block;
}

.title-bg .pic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
  z-index: -1;
}

.title-blur span {
  background-clip: text;
  -webkit-background-clip: text;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(images/bg-blur.jpg);
  color: rgba(0, 0, 0, 0.4);
  font-size: calc(18px + 12*(100vw - 320px)/960);
  font-weight: 700;
  line-height: 1.3;
}

@media all and (-ms-high-contrast: none) {
  .title-blur span {
    background-image: none;
    color: #231815;
  }
}

.text-max {
  font-size: calc(30px + 30*(100vw - 320px)/960);
  line-height: 1.4;
}

.text-xl {
  font-size: calc(20px + 20*(100vw - 320px)/960);
  line-height: 1.4;
}

.text-lg {
  font-size: calc(20px + 10*(100vw - 320px)/960);
  line-height: 1.4;
}

.text-md {
  font-size: calc(16px + 4*(100vw - 320px)/960);
  line-height: 1.4;
}

.text-sm {
  font-size: calc(14px + 2*(100vw - 320px)/960);
  line-height: 1.8;
}

.text-xs {
  font-size: calc(12px + 2*(100vw - 320px)/960);
  line-height: 1.8;
}

.text-min {
  font-size: 12px;
  line-height: 1.8;
}

.text-link a {
  display: inline-block;
  color: #ED4E84;
}

.text-link a:hover {
  color: #930f3c;
}

.text-link a:hover::before {
  border: 2px solid #930f3c;
  border-top-width: 0;
  border-left-width: 0;
}

.text-link a::before {
  display: inline-block;
  width: 6px;
  height: 6px;
  transform: rotate(-45deg);
  border: 2px solid #ED4E84;
  border-top-width: 0;
  border-left-width: 0;
  content: '';
  margin: 0 6px 2px 0;
}

.ft-line {
  position: relative;
  padding: 0 0 20px 0;
  margin: 0 0 20px 0;
  text-align: center;
}

.ft-line::before {
  position: absolute;
  display: block;
  content: '';
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: calc(30px + 30*(100vw - 320px)/960);
  height: 2px;
  border-radius: 5px;
  background: #dedede;
}

.grid-link {
  width: 90%;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
}

@media only screen and (min-width: 768px) {
  .grid-link {
    width: 80%;
  }
}

.grid-link--list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.grid-link--item {
  width: calc(50% - 5px);
  position: relative;
  margin-bottom: 10px;
}

.grid-link--item-full {
  width: 100%;
}

.grid-link .main-title {
  font-size: 1.0rem;
  line-height: 1.3;
  margin-bottom: 10px;
}

.grid-link .caption {
  position: absolute;
  top: 5%;
  width: 100%;
}

.grid-link .caption .title {
  font-size: calc(16px + 8*(100vw - 320px)/960);
  line-height: 1.3;
  text-align: center;
  color: white;
  text-shadow: #767676 0 0 10px;
  font-weight: 500;
}

@media only screen and (max-width: 479.98px) {
  .table-wrap {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media only screen and (max-width: 479.98px) {
  .table-wrap th, .table-wrap td {
    white-space: nowrap;
  }
}

.table-main {
  width: 100%;
  border-collapse: collapse;
}

.table-main tr {
  border-bottom: 1px solid #dedede;
}

.table-main th {
  padding: calc(10px + 10*(100vw - 320px)/960) calc(15px + 15*(100vw - 320px)/960);
  font-weight: 400;
  background: linear-gradient(90deg, #F7F5EE, white);
}

.table-main td {
  padding: calc(10px + 10*(100vw - 320px)/960) calc(15px + 15*(100vw - 320px)/960);
}

@media only screen and (max-width: 479.98px) {
  .table-main.xs-row tr {
    display: block;
  }
}

@media only screen and (max-width: 479.98px) {
  .table-main.xs-row th {
    display: block;
    font-weight: 700;
  }
}

@media only screen and (max-width: 479.98px) {
  .table-main.xs-row td {
    display: block;
    padding: 10px 10px 20px;
  }
}

@media only screen and (max-width: 479.98px) {
  .table-main.xs-row thead {
    display: block;
  }
}

@media only screen and (max-width: 479.98px) {
  .table-main.xs-row tbody {
    display: block;
  }
}

.table-main.nowrap th, .table-main.nowrap td {
  white-space: nowrap;
}

.table-main.th-nowrap th {
  white-space: nowrap;
}

.table-main.t-border th, .table-main.t-border td {
  border: 1px solid #dedede;
}

.table-main.bg-white th, .table-main.bg-white td {
  background-color: white;
}

.table-main.bg-none thead {
  background-color: transparent;
}

.table-main.bg-none th, .table-main.bg-none td {
  background-color: transparent;
}

.table-main.t-noborder {
  border: none;
}

.table-main.t-noborder th, .table-main.t-noborder td {
  border: none;
  padding: 10px;
}

.table-main.th-top tbody th,
.table-main.td-top tbody td {
  vertical-align: top;
}

.table-main.th-middle tbody th,
.table-main.td-middle tbody td {
  vertical-align: middle;
}

.table-main.th-bottom tbody th,
.table-main.td-bottom tbody td {
  vertical-align: bottom;
}

.table-main.thead-left thead,
.table-main.t-left tbody,
.table-main.th-left tbody th,
.table-main.td-left tbody td {
  text-align: left;
}

.table-main.thead-right thead,
.table-main.t-right tbody,
.table-main.th-right tbody th,
.table-main.td-right tbody td {
  text-align: right;
}

.table-main.thead-center thead,
.table-main.t-center tbody,
.table-main.th-center tbody th,
.table-main.td-center tbody td {
  text-align: center;
}

.table-row {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #dedede;
  line-height: 1.3;
}

@media only screen and (min-width: 480px) {
  .table-row {
    border-collapse: collapse;
  }
}

.table-row thead {
  background-color: #F7F5EE;
}

.table-row th, .table-row td {
  border: 1px solid #dedede;
  padding: 20px 10px;
  font-weight: 400;
}

@media only screen and (max-width: 479.98px) {
  .table-row th, .table-row td {
    display: block;
    border: 0;
  }
}

@media only screen and (max-width: 479.98px) {
  .table-row th {
    padding: 15px 10px 10px;
  }
}

@media only screen and (max-width: 479.98px) {
  .table-row td {
    padding: 0 10px 15px;
  }
}

.table-row tr:not(:first-child) th {
  border-top: 1px solid #dedede;
}

.table-form {
  width: 100%;
  border-collapse: collapse;
  border-top: 1px solid #dedede;
}

.table-form tr {
  border-bottom: 1px solid #dedede;
}

@media only screen and (max-width: 479.98px) {
  .table-form tr {
    display: block;
  }
}

.table-form th {
  padding: calc(10px + 10*(100vw - 320px)/960) 0;
  font-weight: 400;
  text-align: left;
}

@media only screen and (max-width: 479.98px) {
  .table-form th {
    display: block;
    font-weight: 700;
  }
}

.table-form th span {
  display: inline-block;
  margin: 0 0 0 10px;
  color: #ED4E84;
  font-size: .75rem;
}

.table-form td {
  padding: calc(10px + 10*(100vw - 320px)/960) 0 calc(10px + 10*(100vw - 320px)/960) calc(15px + 15*(100vw - 320px)/960);
}

@media only screen and (max-width: 479.98px) {
  .table-form td {
    display: block;
    padding: 10px 10px 20px;
  }
}

@media only screen and (max-width: 479.98px) {
  .table-form thead {
    display: block;
  }
}

@media only screen and (max-width: 479.98px) {
  .table-form tbody {
    display: block;
  }
}

.list.sp-md > li + li {
  margin-top: 20px;
}

.list.sp-sm > li + li {
  margin-top: 10px;
}

.list-ori > li {
  position: relative;
  padding-left: 30px;
}

.list-ori > li span {
  position: absolute;
  top: 0;
  left: 0;
}

.list-circle > li {
  position: relative;
  padding-left: 18px;
}

.list-circle > li:before {
  position: absolute;
  content: "●";
  top: 0;
  left: 0;
}

.list-asta > li {
  position: relative;
  padding-left: 18px;
}

.list-asta > li:before {
  position: absolute;
  content: "＊";
  top: 0;
  left: 0;
}

.list-note > li {
  position: relative;
  padding-left: 18px;
}

.list-note > li:before {
  position: absolute;
  content: "※";
  top: 0;
  left: 0;
}

.list-disc > li {
  position: relative;
  padding-left: 14px;
}

.list-disc > li:before {
  position: absolute;
  content: "・";
  top: 0;
  left: 0;
}

.list-number {
  list-style-type: none;
  list-style-position: inside;
}

.list-number > li {
  padding-left: 1.5em;
  text-indent: -1.5em;
  counter-increment: cnt;
  text-align: justify;
}

.list-number > li:before {
  display: marker;
  content: "0" counter(cnt) " ";
  margin-right: 3px;
  color: #767676;
}

.list-number > li:nth-child(n+10):before {
  content: "" counter(cnt) " ";
}

.list-link li a {
  display: inline-block;
  color: #ED4E84;
}

.list-link li a:hover {
  color: #930f3c;
}

.list-link li a:hover::before {
  border: 2px solid #930f3c;
  border-top-width: 0;
  border-left-width: 0;
}

.list-link li a::before {
  display: inline-block;
  width: 6px;
  height: 6px;
  transform: rotate(-45deg);
  border: 2px solid #ED4E84;
  border-top-width: 0;
  border-left-width: 0;
  content: '';
  margin: 0 6px 2px 0;
}

.list-anchor {
  display: block;
  overflow: hidden;
}

@media only screen and (min-width: 480px) {
  .list-anchor {
    display: flex;
    flex-flow: row wrap;
  }
}

@media only screen and (min-width: 480px) {
  .list-anchor.jc-center {
    justify-content: center;
  }
}

.list-anchor li {
  line-height: 1.5;
  margin: 0 0 10px;
}

@media only screen and (min-width: 480px) {
  .list-anchor li {
    margin: 0 10px 10px 0;
  }
}

.list-anchor li span {
  display: block;
  background-color: white;
  border-radius: 50px;
  padding: 10px 20px;
  color: white;
  transition: border .3s, color .3s;
  text-align: center;
  background-color: #67AA1B;
  border: 3px solid #67AA1B;
}

.list-anchor li span svg {
  margin: 0 0 -2px 5px;
  width: 7px;
  height: 14px;
  fill: currentColor;
}

.list-anchor li a {
  display: block;
  border-radius: 50px;
  padding: 10px 20px;
  color: #231815;
  transition: background-color .3s,color .3s;
  text-align: center;
  border: 3px solid #231815;
  font-weight: bold;
}

.list-anchor li a:hover {
  background-color: #ED4E84;
  color: white;
}

.list-anchor li a svg {
  margin: 0 0 -2px 5px;
  width: 7px;
  height: 14px;
  fill: currentColor;
}

.list-flow-down .flow {
  position: relative;
  display: block;
  margin: 0;
  padding: 20px 10px;
  min-width: 120px;
  max-width: 100%;
  color: white;
  background: #008D30;
  text-align: center;
  line-height: 1.3;
  border-radius: 10px;
}

.list-flow-down .flow:not(:last-child):before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -15px;
  border: 15px solid transparent;
  border-top: 15px solid #008D30;
}

.list-flow-down .flow:not(:first-child) {
  margin-top: 30px;
}

.list-flow-down-border .flow {
  position: relative;
  display: block;
  margin: 0;
  padding: 20px;
  min-width: 120px;
  max-width: 100%;
  color: #231815;
  border: solid 3px #008D30;
  background: white;
  text-align: center;
  line-height: 1.3;
  border-radius: 10px;
}

.list-flow-down-border .flow:not(:last-child):before {
  content: "";
  position: absolute;
  bottom: -24px;
  left: 50%;
  margin-left: -15px;
  border: 12px solid transparent;
  border-top: 12px solid white;
  z-index: 2;
}

.list-flow-down-border .flow:not(:last-child):after {
  content: "";
  position: absolute;
  bottom: -30px;
  left: 50%;
  margin-left: -17px;
  border: 14px solid transparent;
  border-top: 14px solid #008D30;
  z-index: 1;
}

.list-flow-down-border .flow:not(:first-child) {
  margin-top: 30px;
}

a:hover img.gray {
  filter: grayscale(0);
}

a img.gray {
  filter: grayscale(100%);
}

.zoom {
  overflow: hidden;
}

a:hover .zoom img {
  transform: scale(1.1);
  opacity: 1;
}

.zoom img {
  transform: translate3d(0, 0, 0);
  transition: transform 5s;
}

.tel-off {
  display: none !important;
}

@media only screen and (min-width: 480px) {
  .tel-off {
    display: inline-block !important;
  }
}

.tel-on {
  display: inline-block !important;
}

@media only screen and (min-width: 480px) {
  .tel-on {
    display: none !important;
  }
}

.fig-caption {
  margin: -10px 0;
}

.fig-caption img {
  padding: 10px 0;
}

.fig-caption figcaption {
  margin: 10px 0;
  font-size: 86%;
  line-height: 1.4;
  color: #767676;
}

.fig-caption-lg {
  margin: 1em calc(50% - 50vw);
}

.fig-caption-lg img {
  width: 100%;
  height: auto;
}

.fig-caption-lg figcaption {
  margin: 10px auto;
  max-width: 960px;
  padding: 0 5%;
  font-size: 86%;
  line-height: 1.4;
  color: #767676;
}

.release-pic {
  margin: 1em calc(50% - 50vw);
}

.release-pic .pic {
  width: 100%;
  height: 600px;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
}

.release-pic .notes {
  margin: 10px auto;
  max-width: 960px;
  padding: 0 5%;
  font-size: 86%;
  line-height: 1.4;
  color: #767676;
}

@media only screen and (min-width: 768px) {
  .dl-basic {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
}

.dl-basic dt {
  padding: 20px 0 0;
  color: #008D30;
}

@media only screen and (min-width: 768px) {
  .dl-basic dt {
    flex: 0 0 28%;
    width: 30%;
    padding: 20px 0;
    border-bottom: 1px solid #008D30;
  }
}

.dl-basic dd {
  padding: 5px 0 20px;
  border-bottom: 1px solid #dedede;
}

@media only screen and (min-width: 768px) {
  .dl-basic dd {
    flex: 0 0 70%;
    width: 70%;
    padding: 20px 0;
  }
}

@media only screen and (min-width: 768px) {
  .dl-price {
    display: flex;
    flex-flow: row wrap;
  }
}

.dl-price dt {
  color: #008D30;
}

@media only screen and (min-width: 768px) {
  .dl-price dt {
    flex: 0 0 30%;
    width: 30%;
  }
}

@media only screen and (min-width: 768px) {
  .dl-price dd {
    flex: 0 0 70%;
    width: 70%;
  }
}

.dl-price dd:before {
  content: ' …… ';
}

/* movie youtube
--------------------------------------------- */
.iframe-wrap {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 62.5%;
}

.iframe-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-wrap {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 62.5%;
}

.video-wrap video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* sef
--------------------------------------------- */
.sef {
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.sef.sef-ori {
  opacity: 1;
}

.sef.show {
  opacity: 1;
  transform: none;
}

.sef-left {
  transform: translate(-100px, 0);
}

.sef-right {
  transform: translate(100px, 0);
}

.sef-up {
  transform: translate(0, 100px);
}

.sef-down {
  transform: translate(0, -100px);
}

.sef-scaleUp {
  transform: scale(0.5);
}

.sef-scaleDown {
  transform: scale(1.5);
}

/* flexbox
--------------------------------------------- */
.as-center {
  align-self: center;
}

.as-start {
  align-self: flex-start;
}

.as-end {
  align-self: flex-end;
}

.as-stretch {
  align-self: stretch;
}

.as-baseline {
  align-self: baseline;
}

.as-auto {
  align-self: auto;
}

/* カラー
--------------------------------------------- */
.c-white {
  color: white !important;
}

.c-black {
  color: #231815 !important;
}

.c-aux {
  color: #767676 !important;
}

.c-pale {
  color: #dedede !important;
}

.c-thin {
  color: #F7F5EE !important;
}

.c-main {
  color: #008D30 !important;
}

.c-key {
  color: #67AA1B !important;
}

.c-link {
  color: #ED4E84 !important;
}

.c-em {
  color: #ED4E84 !important;
}

.bg-white {
  background-color: white !important;
}

.bg-black {
  background-color: #231815 !important;
}

.bg-aux {
  background-color: #767676 !important;
}

.bg-pale {
  background-color: #dedede !important;
}

.bg-thin {
  background-color: #F7F5EE !important;
}

.bg-key {
  background-color: #67AA1B !important;
}

.bg-key-thin {
  background-color: #b3e975 !important;
}

.bg-gray {
  background-color: #F5F5F5 !important;
}

/* スペース
--------------------------------------------- */
.mt-skin {
  margin-top: 5px !important;
}

.mt-min {
  margin-top: 10px !important;
}

.mt-xs {
  margin-top: 20px !important;
}

.mt-sm {
  margin-top: 30px !important;
}

.mt-md {
  margin-top: calc(40px + 20*(100vw - 320px)/960) !important;
}

.mt-lg {
  margin-top: calc(60px + 20*(100vw - 320px)/960) !important;
}

.mt-xl {
  margin-top: calc(80px + 40*(100vw - 320px)/960) !important;
}

.mt-max {
  margin-top: calc(120px + 40*(100vw - 320px)/960) !important;
}

.mr-skin {
  margin-right: 5px !important;
}

.mr-min {
  margin-right: 10px !important;
}

.mr-xs {
  margin-right: 20px !important;
}

.mr-sm {
  margin-right: 30px !important;
}

.ml-skin {
  margin-left: 5px !important;
}

.ml-min {
  margin-left: 10px !important;
}

.ml-xs {
  margin-left: 20px !important;
}

.ml-sm {
  margin-left: 30px !important;
}

.mb-skin {
  margin-bottom: 5px !important;
}

.mb-min {
  margin-bottom: 10px !important;
}

.mb-xs {
  margin-bottom: 20px !important;
}

.mb-sm {
  margin-bottom: 30px !important;
}

.mb-md {
  margin-bottom: calc(40px + 20*(100vw - 320px)/960) !important;
}

.mb-lg {
  margin-bottom: calc(60px + 20*(100vw - 320px)/960) !important;
}

.mb-xl {
  margin-bottom: calc(80px + 40*(100vw - 320px)/960) !important;
}

.mb-max {
  margin-bottom: calc(120px + 40*(100vw - 320px)/960) !important;
}

.pt-skin {
  padding-top: 5px !important;
}

.pt-min {
  padding-top: 10px !important;
}

.pt-xs {
  padding-top: 20px !important;
}

.pt-sm {
  padding-top: 30px !important;
}

.pr-skin {
  padding-right: 5px !important;
}

.pr-min {
  padding-right: 10px !important;
}

.pr-xs {
  padding-right: 20px !important;
}

.pr-sm {
  padding-right: 30px !important;
}

.pl-skin {
  padding-left: 5px !important;
}

.pl-min {
  padding-left: 10px !important;
}

.pl-xs {
  padding-left: 20px !important;
}

.pl-sm {
  padding-left: 30px !important;
}

.pb-skin {
  padding-bottom: 5px !important;
}

.pb-min {
  padding-bottom: 10px !important;
}

.pb-xs {
  padding-bottom: 20px !important;
}

.pb-sm {
  padding-bottom: 30px !important;
}

.wf-active .ff-en {
  font-family: "EB Garamond", serif !important;
}

.ff-en {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "ヒラギノ角ゴシック Pro", "hiragino kaku gothic pro", meiryo, "メイリオ", sans-serif;
}

.fw-b {
  font-weight: 700 !important;
}

.fw-n {
  font-weight: 400 !important;
}

.fs-min {
  font-size: 72% !important;
}

.fs-xs {
  font-size: 86% !important;
}

.fs-sm {
  font-size: 93% !important;
}

.fs-md {
  font-size: 115% !important;
}

.fs-lg {
  font-size: 143% !important;
}

.fs-xl {
  font-size: 143% !important;
}

@media only screen and (min-width: 768px) {
  .fs-xl {
    font-size: 172% !important;
  }
}

.fs-max {
  font-size: 172% !important;
}

@media only screen and (min-width: 768px) {
  .fs-max {
    font-size: 300% !important;
  }
}

.fs10 {
  font-size: 0.625rem !important;
}

.fs11 {
  font-size: 0.6875rem !important;
}

.fs12 {
  font-size: 0.75rem !important;
}

.fs13 {
  font-size: 0.8125rem !important;
}

.fs14 {
  font-size: 0.875rem !important;
}

.fs16 {
  font-size: 1.0rem !important;
}

.fs18 {
  font-size: 1.125rem !important;
}

.fs20 {
  font-size: 1.25rem !important;
}

.fs24 {
  font-size: 1.5rem !important;
}

.fs28 {
  font-size: 1.75rem !important;
}

.fs30 {
  font-size: 1.875rem !important;
}

.fs32 {
  font-size: 2.0rem !important;
}

.fs36 {
  font-size: 2.25rem !important;
}

.fs40 {
  font-size: 2.5rem !important;
}

.fs48 {
  font-size: 3.0rem !important;
}

.lh10 {
  line-height: 1.0 !important;
}

.lh11 {
  line-height: 1.1 !important;
}

.lh12 {
  line-height: 1.2 !important;
}

.lh13 {
  line-height: 1.3 !important;
}

.lh14 {
  line-height: 1.4 !important;
}

.lh15 {
  line-height: 1.5 !important;
}

.lh18 {
  line-height: 1.8 !important;
}

.lh20 {
  line-height: 2.0 !important;
}

.ta-l {
  text-align: left !important;
}

.ta-c {
  text-align: center !important;
}

.ta-r {
  text-align: right !important;
}

.ta-j {
  text-align: justify !important;
}

.ta-cl {
  text-align: left !important;
}

@media only screen and (min-width: 768px) {
  .ta-cl {
    text-align: center !important;
  }
}

@media only screen and (max-width: 479.98px) {
  .xs-w90c {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  }
}

.xl-on {
  display: none;
}

@media only screen and (min-width: 1280px) {
  .xl-on {
    display: block;
  }
}

@media only screen and (min-width: 1280px) {
  .xl-no {
    display: none;
  }
}

.lg-on {
  display: none;
}

@media only screen and (min-width: 1000px) {
  .lg-on {
    display: block;
  }
}

@media only screen and (min-width: 1000px) {
  .lg-no {
    display: none;
  }
}

.md-on {
  display: none;
}

@media only screen and (min-width: 768px) {
  .md-on {
    display: block;
  }
}

@media only screen and (min-width: 768px) {
  .md-no {
    display: none;
  }
}

.sm-on {
  display: none;
}

@media only screen and (min-width: 480px) {
  .sm-on {
    display: block;
  }
}

@media only screen and (min-width: 480px) {
  .sm-no {
    display: none;
  }
}

.xs-on {
  display: none;
}

@media only screen and (max-width: 479.98px) {
  .xs-on {
    display: block;
  }
}

.xs-no {
  display: block;
}

@media only screen and (max-width: 479.98px) {
  .xs-no {
    display: none;
  }
}

.d-block {
  display: block;
}

.d-iblock {
  display: inline-block;
}

.d-table {
  display: table;
}

.d-tablec {
  display: table-cell;
}

.d-inline {
  display: inline;
}

@media only screen and (min-width: 480px) {
  .fl-r {
    float: right;
  }
}

@media only screen and (min-width: 480px) {
  .fl-l {
    float: left;
  }
}

figure.fl-r {
  margin: 0 0 20px;
}

@media only screen and (min-width: 480px) {
  figure.fl-r {
    margin: 0 0 20px 20px;
  }
}

figure.fl-l {
  margin: 0 0 20px;
}

@media only screen and (min-width: 480px) {
  figure.fl-l {
    margin: 0 20px 20px 0;
  }
}

.clearfix {
  display: block;
  min-height: 1%;
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
  visibility: hidden;
}

* html .clearfix {
  height: 1%;
  /*\*/
  /*/
    height: auto;
    overflow: hidden;
    /**/
}

.clear {
  clear: both;
}

.home.is-animation .header {
  background-color: white;
}

.home.is-animation .header-logo .logo svg {
  fill: #231815;
}

.home.is-animation .header-nav li a {
  color: #231815;
}

.home.is-animation .header-tel {
  color: #231815;
}

.home .header {
  background-color: inherit;
  box-shadow: none;
}

.home .header-logo .logo svg {
  fill: white;
}

.home .header-nav li a {
  color: white;
}

.home .header-tel {
  color: white;
}

.home #main {
  padding: 0;
}

.header {
  z-index: 1000;
  position: fixed;
  width: 100%;
  background-color: white;
}

.header-inner {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 8px 20px 12px;
}

.header-logo .logo {
  line-height: 1.0;
}

.header-logo .logo svg {
  width: 214px;
  height: 50px;
}

@media only screen and (min-width: 768px) {
  .header-logo .logo svg {
    width: 347px;
    height: 81px;
  }
}

.header-logo .logo svg .st0 {
  fill: #008D30;
}

.header-logo .logo svg .st1 {
  fill: #67AA1B;
}

.header-nav {
  display: none;
}

@media only screen and (min-width: 1000px) {
  .header-nav {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
  }
}

@media only screen and (min-width: 768px) {
  .header-menu {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }
}

.header-menu li {
  font-size: 0.875rem;
  line-height: 1.2;
  margin: 0 20px 0 0;
}

.header-menu li a {
  display: inline-block;
  color: #231815;
}

.header-tel {
  margin: 0 20px 0 0;
  line-height: 1;
  color: #231815;
  font-size: 1.25rem;
  font-weight: bold;
}

.header-tel img {
  display: inline-block;
  margin: 0 1px 0 0;
}

.header-contact {
  font-size: 0.875rem;
  line-height: 1.2;
}

.header-contact a {
  padding: 10px 20px;
  background-color: #008D30;
  color: white;
  display: inline-block;
}

.header-contact a:after {
  display: inline-block;
  width: 6px;
  height: 6px;
  transform: rotate(-45deg);
  border: 2px solid white;
  border-top-width: 0;
  border-left-width: 0;
  content: '';
  margin: 0 0 2px 6px;
}

.header-en {
  display: none;
  margin: 0 0 0 20px;
  font-size: 0.875rem;
  line-height: 1.2;
}

.header-en a {
  display: inline-block;
  padding: 10px;
  border-radius: 100px;
  background-color: #67AA1B;
  color: white;
}

.header-banner {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  position: absolute;
  right: 50%;
  bottom: -25px;
  transform: translate(50%, 0);
}

@media only screen and (min-width: 768px) {
  .header-banner {
    display: block;
    right: 20px;
    bottom: -80px;
    transform: none;
  }
}

.header-banner a {
  display: block;
  width: 130px;
  text-align: center;
  padding: 7px 5px;
  border-radius: 100px;
  line-height: 1;
  font-size: 0.75rem;
}

@media only screen and (min-width: 768px) {
  .header-banner a {
    width: 190px;
    padding: 10px 5px;
    font-size: 1rem;
  }
}

.header-banner a span {
  display: none;
}

@media only screen and (min-width: 768px) {
  .header-banner a span {
    display: inline;
  }
}

.header-banner a.job {
  border: 2px solid #008D30;
  background-color: white;
  color: #008D30;
  margin: 0 10px 0 0;
}

@media only screen and (min-width: 768px) {
  .header-banner a.job {
    margin: 0 0 10px;
  }
}

.header-banner a.offer {
  border: 2px solid white;
  background-color: #008D30;
  color: white;
}

#nav-drawer {
  position: relative;
}

@media only screen and (min-width: 1000px) {
  #nav-drawer {
    display: none;
  }
}

.nav-unshown {
  display: none;
}

#nav-open {
  display: inline-block;
  width: 30px;
  height: 22px;
  vertical-align: middle;
  transition: .3s;
  cursor: pointer;
}

#nav-open span, #nav-open span:before, #nav-open span:after {
  position: absolute;
  height: 3px;
  width: 25px;
  border-radius: 3px;
  background: #231815;
  display: block;
  content: '';
  cursor: pointer;
  transition: .8s;
}

.home #nav-open span, .home #nav-open span:before, .home #nav-open span:after {
  background: white;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
}

.home.is-animation #nav-open span, .home.is-animation #nav-open span:before, .home.is-animation #nav-open span:after {
  background: #231815;
  box-shadow: none;
}

#nav-open span:before {
  bottom: -8px;
}

#nav-open span:after {
  bottom: -16px;
}

#nav-close {
  display: none;
  position: fixed;
  z-index: 99;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0;
  transition: .3s ease-in-out;
}

#nav-content {
  overflow: auto;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
  width: 90%;
  max-width: 280px;
  height: 100%;
  background: linear-gradient(-135deg, #67AA1B, #008D30);
  transition: .3s ease-in-out;
  transform: translateX(105%);
  padding: 20px;
}

#nav-content .en {
  display: none;
}

#nav-input:checked ~ #nav-close {
  display: block;
  opacity: .5;
}

#nav-input:checked ~ #nav-open {
  z-index: 999;
  position: fixed;
  right: 280px;
}

#nav-input:checked ~ #nav-open span,
#nav-input:checked ~ #nav-open span:before,
#nav-input:checked ~ #nav-open span:after {
  background: white;
  z-index: 999;
}

#nav-input:checked ~ #nav-open span {
  transform: rotate(45deg);
}

#nav-input:checked ~ #nav-open span:before {
  bottom: 0;
  transform: rotate(-90deg);
}

#nav-input:checked ~ #nav-open span:after {
  bottom: 0;
  opacity: 0;
}

#nav-input:checked ~ #nav-content {
  transform: translateX(0%);
  box-shadow: -6px 0 25px rgba(0, 0, 0, 0.15);
}

#nav-menu li {
  text-align: left;
  padding: 10px 0;
  border-bottom: 1px solid #008D30;
}

#nav-menu li a {
  display: block;
  color: white;
}

#nav-menu li a:hover {
  color: #ED4E84;
}

#nav-menu li ul {
  margin: 10px 0 0;
}

#nav-menu li li {
  padding: 5px 0px 5px 20px;
  position: relative;
  border-bottom: none;
}

#nav-menu li li:before {
  content: '';
  position: absolute;
  width: 10px;
  height: 1px;
  background-color: #67AA1B;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
}

.nav-banner a {
  display: block;
  width: 100%;
  text-align: center;
  padding: 10px 5px;
  border-radius: 100px;
  line-height: 1;
  margin: 0 0 10px;
}

.nav-banner a.job {
  border: 2px solid #008D30;
  background-color: white;
  color: #008D30;
}

.nav-banner a.offer {
  border: 2px solid white;
  background-color: #008D30;
  color: white;
}

#main {
  display: block;
  z-index: 0;
  background-color: #F7F5EE;
  padding: 71px 0 0;
}

@media only screen and (min-width: 768px) {
  #main {
    padding: 103px 0 0 0;
    width: 100%;
  }
}

.wf-active .sec-hero .title {
  font-family: "Yu Mincho", "YuMincho", "Noto Serif JP", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.sec-hero {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: calc(220px + 240*(100vw - 320px)/960);
}

.sec-hero .title {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: calc(30px + 30*(100vw - 320px)/960);
  font-weight: bold;
  transform: translate(-50%, -50%);
  z-index: 10;
  color: white;
  width: 90%;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "ヒラギノ角ゴシック Pro", "hiragino kaku gothic pro", meiryo, "メイリオ", sans-serif;
  line-height: 1.3;
}

.sec-hero .title span {
  display: block;
  font-size: 1.25rem;
}

.sec-hero .slider {
  width: 100%;
  height: calc(220px + 240*(100vw - 320px)/960);
}

.sec-hero .slider .slide {
  width: 100%;
  height: calc(220px + 240*(100vw - 320px)/960);
  overflow: hidden;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.sec-hero .slider .slide.slide1 {
  background-image: url(assets/images/mv-hero1.jpg);
}

.sec-hero .slider .slide.slide2 {
  background-image: url(assets/images/mv-hero2.jpg);
}

.sec-hero .slider .slide.slide3 {
  background-image: url(assets/images/mv-hero3.jpg);
}

.sec-hero .slider .slide.slide4 {
  background-image: url(assets/images/mv-hero4.jpg);
}

.sec-hero .slider .slide.slide5 {
  background-image: url(assets/images/mv-hero5.jpg);
}

ul.hero-panel li.panel34 {
  width: 100%;
}

ul.hero-panel li.panel34 {
  width: 100%;
}

ul.hero-panel li.panel33 img, ul.hero-panel li.panel34 img {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  ul.hero-panel li.panel33 {
    width: 33%;
    float: left;
  }
  ul.hero-panel li.panel34 {
    width: 34%;
    float: left;
  }
}

ul.hero-panel img {
  width: 100%;
  height: auto;
  border: solid 1px #CCC;
}

.wf-active .page-head .sub,
.wf-active .post-head .sub {
  font-family: "EB Garamond", serif;
}

.page-head {
  background: linear-gradient(-135deg, #67AA1B, #008D30);
  padding: 40px 20px;
  overflow: hidden;
  position: relative;
}

@media only screen and (min-width: 768px) {
  .page-head {
    padding: 80px 20px;
  }
}

.page-head .title {
  font-size: calc(20px + 20*(100vw - 320px)/960);
  letter-spacing: 10px;
  font-weight: 700;
  color: white;
  line-height: 1.0;
  text-align: center;
}

.page-head .sub {
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%, 0);
  font-style: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "ヒラギノ角ゴシック Pro", "hiragino kaku gothic pro", meiryo, "メイリオ", sans-serif;
  font-size: 30px;
  letter-spacing: 2px;
  color: rgba(247, 245, 238, 0.4);
  line-height: 1.0;
  width: 95%;
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .page-head .sub {
    font-size: 80px;
    bottom: -20px;
    letter-spacing: 5px;
  }
}

.post-head {
  background: linear-gradient(-135deg, #67AA1B, #008D30);
  padding: 40px 20px;
  overflow: hidden;
  position: relative;
}

@media only screen and (min-width: 768px) {
  .post-head {
    padding: 80px 20px;
  }
}

.post-head .title {
  font-size: calc(20px + 20*(100vw - 320px)/960);
  letter-spacing: 2px;
  line-height: 1.3;
  font-weight: 700;
  color: white;
  line-height: 1.0;
  text-align: center;
}

.post-head .sub {
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 30px;
  font-style: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "ヒラギノ角ゴシック Pro", "hiragino kaku gothic pro", meiryo, "メイリオ", sans-serif;
  letter-spacing: 2px;
  color: rgba(247, 245, 238, 0.4);
  line-height: 1.0;
  width: 95%;
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .post-head .sub {
    font-size: 80px;
    bottom: -20px;
    letter-spacing: 5px;
  }
}

.sec-mv {
  position: relative;
  width: 100%;
  height: auto;
  background: center center / cover no-repeat;
  opacity: 0;
  transition: opacity 2s ease-in;
}

.sec-mv:before {
  display: block;
  padding-top: 160px;
  content: '';
}

@media only screen and (min-width: 768px) {
  .sec-mv:before {
    padding-top: 320px;
  }
}

.sec-mv img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
  z-index: -1;
}

.action .sec-mv {
  opacity: 1;
}

.action .sec-mv .block .title {
  opacity: 1;
  transform: translate(0, 0);
}

.action .sec-mv .block .sup {
  opacity: 1;
  transform: translate(0, 0);
}

.sec-mv .block {
  position: absolute;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  width: 100%;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  line-height: 1.2;
}

.sec-mv .block .title {
  font-size: calc(30px + 10*(100vw - 320px)/960);
  font-weight: normal;
  line-height: 1.2;
  color: white;
  opacity: 0;
  transform: translate(0, 40px);
  transition: opacity 1s ease-out .5s, transform 1s ease-out .5s;
}

.sec-mv .block .sup {
  font-size: calc(16px + 6*(100vw - 320px)/960);
  line-height: 1.2;
  color: white;
  margin: 20px 0 0;
  padding: 20px 0 0;
  position: relative;
  opacity: 0;
  transform: translate(0, 40px);
  transition: opacity 1s ease-out 1s, transform 1s ease-out 1s;
}

.sec-mv .block .sup::before {
  display: block;
  content: '';
  width: 60px;
  height: 1px;
  background-color: #F7F5EE;
  top: 0;
  left: 0;
  left: 50%;
  position: absolute;
  transform: translate(-50%, 0);
}

.sec-mv.block-reverse {
  flex-direction: column;
}

.sec-mv.block-reverse .sup {
  margin: 0 0 10px;
}

.container {
  width: 100%;
  background-color: #F7F5EE;
  padding: 30px 0 60px;
}

@media only screen and (min-width: 768px) {
  .container {
    padding: 60px 0 120px;
  }
}

.wf-active .title-top span {
  font-family: "EB Garamond", serif;
}

.title-top {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.0;
}

.title-top span {
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "ヒラギノ角ゴシック Pro", "hiragino kaku gothic pro", meiryo, "メイリオ", sans-serif;
  font-size: calc(40px + 40*(100vw - 320px)/960);
  font-weight: 400;
  line-height: 1.0;
}

.wf-active .sec-top-aboutus .title {
  font-family: "Yu Mincho", "YuMincho", "Noto Serif JP", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.sec-top-aboutus {
  width: 90%;
  margin-right: auto;
  margin-left: auto;
}

.sec-top-aboutus .title {
  font-size: calc(20px + 10*(100vw - 320px)/960);
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "ヒラギノ角ゴシック Pro", "hiragino kaku gothic pro", meiryo, "メイリオ", sans-serif;
}

.wf-active .sec-top-service article .box .title {
  font-family: "Yu Mincho", "YuMincho", "Noto Serif JP", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.wf-active .sec-top-service article .box .title span {
  font-family: "EB Garamond", serif;
}

.sec-top-service {
  width: 100%;
}

@media only screen and (min-width: 1280px) {
  .sec-top-service {
    max-width: 1400px;
    margin-right: auto;
    margin-left: auto;
  }
}

.sec-top-service article {
  position: relative;
  width: 100%;
  padding: 20px;
}

@media only screen and (min-width: 1000px) {
  .sec-top-service article {
    padding: 80px;
  }
}

.sec-top-service article.reverse .box {
  width: 100%;
}

@media only screen and (min-width: 1000px) {
  .sec-top-service article.reverse .box {
    width: 50%;
    background: linear-gradient(90deg, #F7F5EE, white);
    padding: 80px;
    margin-left: auto;
    margin-right: inherit;
  }
}

.sec-top-service article.reverse .box .inner {
  width: 100%;
}

.sec-top-service article.reverse figure {
  width: 100%;
}

@media only screen and (min-width: 1000px) {
  .sec-top-service article.reverse figure {
    top: 0;
    left: 0;
    right: inherit;
    width: 50%;
    margin-right: auto;
  }
}

.sec-top-service article .box {
  width: 100%;
  margin: 20px 0 0;
}

@media only screen and (min-width: 1000px) {
  .sec-top-service article .box {
    width: 50%;
    background: linear-gradient(90deg, white, #F7F5EE);
    padding: 80px;
    margin-right: auto;
  }
}

.sec-top-service article .box .inner {
  width: 100%;
}

.sec-top-service article .box .title {
  font-size: calc(20px + 10*(100vw - 320px)/960);
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "ヒラギノ角ゴシック Pro", "hiragino kaku gothic pro", meiryo, "メイリオ", sans-serif;
}

.sec-top-service article .box .title span {
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "ヒラギノ角ゴシック Pro", "hiragino kaku gothic pro", meiryo, "メイリオ", sans-serif;
  color: #67AA1B;
  font-weight: 400;
  font-size: 1rem;
}

.sec-top-service article figure {
  width: 100%;
}

@media only screen and (min-width: 1000px) {
  .sec-top-service article figure {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    margin-left: auto;
  }
}

.sec-top-service article .list-link {
  font-size: 1.25rem;
}

.sec-top-info {
  width: 90%;
  margin-right: auto;
  margin-left: auto;
}

@media only screen and (min-width: 1280px) {
  .sec-top-info {
    max-width: 1200px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
}

.sec-top-news {
  width: 100%;
}

@media only screen and (min-width: 1280px) {
  .sec-top-news {
    width: calc(100% - 338px);
    flex: 0 0 calc(100% - 338px);
  }
}

.news-list-top {
  margin: 30px 0 0;
}

.news-list-top dl {
  width: 100%;
  background-color: white;
  border-bottom: 1px solid #F5F5F5;
}

@media only screen and (min-width: 768px) {
  .news-list-top dl {
    display: flex;
    flex-flow: row wrap;
  }
}

.news-list-top dt {
  padding: 20px 20px 0;
}

@media only screen and (min-width: 768px) {
  .news-list-top dt {
    padding: 20px;
    flex: 0 0 130px;
    width: 130px;
  }
}

.news-list-top dd {
  padding: 10px 20px 20px;
}

@media only screen and (min-width: 768px) {
  .news-list-top dd {
    padding: 20px;
    flex: 0 0 calc(100% - 130px);
    width: calc(100% - 130px);
  }
}

.sec-top-sns {
  margin-top: 40px;
}

@media only screen and (min-width: 1280px) {
  .sec-top-sns {
    margin-top: 0;
  }
}

.wf-active .sec-tocontact .title span {
  font-family: "EB Garamond", serif;
}

.sec-tocontact .title {
  font-size: 1rem;
  text-align: center;
  font-weight: 700;
  line-height: 1.0;
}

.sec-tocontact .title span {
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "ヒラギノ角ゴシック Pro", "hiragino kaku gothic pro", meiryo, "メイリオ", sans-serif;
  font-size: calc(40px + 40*(100vw - 320px)/960);
  font-weight: 400;
  line-height: 1.0;
}

/* 会社情報 */
.sec-profile th {
  width: 100%;
}

@media only screen and (min-width: 480px) {
  .sec-profile th {
    width: 200px;
  }
}

.sec-profile .group figure {
  text-align: left;
}

.sec-profile .group figure img {
  width: 200px;
  height: auto;
}

.sec-map iframe {
  width: 100%;
  height: 320px;
}

/* 事業紹介 */
.list-service {
  margin: 0 0 -40px;
}

@media only screen and (min-width: 768px) {
  .list-service {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
}

.list-service > li {
  padding: 0 0 40px;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .list-service > li {
    width: 48%;
    flex: 0 0 48%;
  }
}

.list-service a:hover .title {
  color: #ED4E84;
}

.list-service .title {
  margin: 10px 0 0;
  color: #231815;
}

/* 職業紹介事業 */
.wf-active .list-flow li .title span,
.wf-active .list-flow li .tel {
  font-family: "EB Garamond", serif;
}

.list-flow li {
  padding: 20px;
  background: linear-gradient(180deg, white, #F7F5EE);
  position: relative;
}

@media only screen and (min-width: 768px) {
  .list-flow li {
    padding: 30px 30px 30px 100px;
  }
}

.list-flow li:not(:last-child) {
  margin: 0 0 30px;
}

.list-flow li:not(:last-child):before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  border: 30px solid transparent;
  border-top: 30px solid #F7F5EE;
}

.list-flow li .title {
  font-size: 1.25rem;
  font-weight: 700;
  color: #008D30;
}

.list-flow li .title span {
  display: inline-block;
  color: #008D30;
  font-size: 1.25rem;
  font-weight: 400;
  margin: 0 10px 0 0;
}

@media only screen and (min-width: 768px) {
  .list-flow li .title span {
    line-height: 1.0;
    position: absolute;
    font-size: calc(20px + 60*(100vw - 320px)/960);
    top: 30px;
    left: 30px;
    margin: 0;
  }
}

.list-flow li .tel {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "ヒラギノ角ゴシック Pro", "hiragino kaku gothic pro", meiryo, "メイリオ", sans-serif;
  font-size: calc(20px + 20*(100vw - 320px)/960);
  font-weight: 700;
  color: #008D30;
}

.list-flow li .tel span {
  font-size: 1rem;
  display: inline-block;
  margin: 0 10px 0 0;
}

/* 登録支援機関 */
.dl-time {
  display: flex;
  flex-flow: row wrap;
}

.dl-time dt {
  flex: 0 0 160px;
  width: 160px;
}

.dl-time dd {
  flex: 0 0 calc(100% - 160px);
  width: calc(100% - 160px);
}

/* お知らせ一覧 */
.news-list {
  margin: 30px 0 0;
}

.news-list dl {
  width: 100%;
  background-color: white;
  border-bottom: 1px solid #F7F5EE;
}

@media only screen and (min-width: 768px) {
  .news-list dl {
    display: flex;
    flex-flow: row wrap;
  }
}

.news-list dt {
  padding: 20px 20px 0;
}

@media only screen and (min-width: 768px) {
  .news-list dt {
    padding: 20px 0;
    flex: 0 0 130px;
    width: 130px;
  }
}

.news-list dd {
  padding: 10px 20px 20px;
}

@media only screen and (min-width: 768px) {
  .news-list dd {
    padding: 20px 0 20px 20px;
    flex: 0 0 calc(100% - 130px);
    width: calc(100% - 130px);
  }
}

/* お知らせ */
.post-top {
  padding: 0 0 30px;
  margin: 0 0 30px;
  border-bottom: 1px solid #dedede;
}

.post-top .title-main {
  text-align: center;
}

.post-top .date {
  text-align: center;
}

/* サイトマップ */
.list-sitemap {
  border-top: 1px solid #dedede;
  width: 100%;
}

.list-sitemap li {
  border-bottom: 1px solid #dedede;
  padding: 20px 0;
}

.list-sitemap .lv2 {
  padding: 20px 0 0 40px;
}

.list-sitemap .lv2 li:last-child {
  border-bottom: none;
}

.list-sitemap a {
  color: #231815;
}

.list-sitemap a:hover {
  color: #ED4E84;
}

.wf-active .footer-foot .copyright {
  font-family: "EB Garamond", serif;
}

.footer {
  position: relative;
  padding: 30px 20px 60px;
}

@media only screen and (min-width: 768px) {
  .footer {
    padding: 70px 20px 120px;
  }
}

.footer::before {
  position: absolute;
  width: 100%;
  height: 10px;
  background: linear-gradient(90deg, #67AA1B, #008D30);
  top: 0;
  left: 0;
  content: '';
  display: block;
}

.footer-inner {
  max-width: 960px;
  margin-right: auto;
  margin-left: auto;
}

@media only screen and (min-width: 768px) {
  .footer-body {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
}

.footer-foot .copyright {
  margin: 40px 0 0;
  text-align: right;
  font-size: .875rem;
  line-height: 1.0;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "ヒラギノ角ゴシック Pro", "hiragino kaku gothic pro", meiryo, "メイリオ", sans-serif;
}

.footer-logo .logo {
  line-height: 1.0;
}

.footer-logo .logo svg {
  width: 214px;
  height: 50px;
}

@media only screen and (min-width: 768px) {
  .footer-logo .logo svg {
    width: 347px;
    height: 81px;
  }
}

.footer-logo .logo svg .st0 {
  fill: #008D30;
}

.footer-logo .logo svg .st1 {
  fill: #67AA1B;
}

.footer-sns {
  padding: 0 0 0 20px;
  text-align: right;
}

.footer-sns a {
  display: inline-block;
  border-radius: 100px;
  background-color: white;
  padding: 10px;
  line-height: 1.0;
}

.footer-sns a:hover {
  color: white;
}

.footer-sns a svg {
  width: 20px;
  height: 20px;
  fill: currentColor;
}

.footer-address {
  margin: 20px 0 0;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .footer-address {
    margin: 0;
    width: 40%;
  }
}

.footer-address .address-list li {
  font-size: 0.875rem;
  line-height: 1.5;
  color: white;
}

.footer-address .address-list li:not(:first-child) {
  margin: 20px 0 0;
}

.footer-address .address-list li a {
  font-weight: bold;
  color: white;
}

.footer-address .address-list li span {
  white-space: nowrap;
}

.footer-info p {
  line-height: 1.8;
}

.footer-link {
  margin: 20px 0 0;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .footer-link {
    margin: 0;
    width: 50%;
  }
}

@media only screen and (min-width: 768px) {
  .footer-link .link-list {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
}

.footer-link .link-list div {
  width: 100%;
  margin: 0 0 10px;
}

.footer-link .link-list div > p {
  font-size: 1rem;
  line-height: 1.5;
  color: #231815;
}

@media only screen and (min-width: 768px) {
  .footer-link .link-list div > p {
    font-size: 0.75rem;
  }
}

.footer-link .link-list div .list-child-lv2 li {
  line-height: 1.5;
  color: #231815;
  margin: 10px 0 0;
}

.footer-link .link-list .separate {
  margin: 10px 0 0;
}

@media only screen and (min-width: 768px) {
  .footer-link .link-list .separate {
    margin: 30px 0 0;
  }
}

.footer-link .link-list a {
  display: inline-block;
  position: relative;
  color: #231815;
  padding: 0 0 0 10px;
}

.footer-link .link-list a::before {
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  width: 5px;
  height: 5px;
  transform: rotate(-45deg) translate(0, -50%);
  border: 2px solid #231815;
  border-top-width: 0;
  border-left-width: 0;
  content: '';
}

.footer-bnr {
  margin: 40px 0 0;
}

.is-animation #gnav {
  display: inline-block;
}

.gnav--burger {
  position: fixed;
  top: 40px;
  right: 20px;
  display: inline-block;
  white-space: nowrap;
  text-align: center;
  z-index: 1000;
  display: block;
}

@media only screen and (min-width: 768px) {
  .gnav--burger {
    top: 30px;
    right: 30px;
    display: none;
  }
}

.gnav--burger.active div:nth-of-type(2) {
  opacity: 0;
}

.gnav--burger-icon {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 16px;
}

.gnav--burger-icon div {
  position: absolute;
  display: inline-block;
  transition: transform .3s;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #231815;
}

.gnav--burger-icon div:nth-of-type(1) {
  top: 0;
  -webkit-animation: burger-bar01 .75s forwards;
  animation: burger-bar01 .75s forwards;
}

.gnav--burger-icon div:nth-of-type(2) {
  top: 7px;
  opacity: 1;
}

.gnav--burger-icon div:nth-of-type(3) {
  bottom: 0;
  -webkit-animation: burger-bar03 .75s forwards;
  animation: burger-bar03 .75s forwards;
}

.gnav--burger-text {
  display: block;
  font-size: 0.75rem;
  color: #231815;
  line-height: 1;
  font-weight: 400;
}

@-webkit-keyframes burger-bar01 {
  0% {
    -webkit-transform: translateY(7px) rotate(45deg);
  }
  50% {
    -webkit-transform: translateY(7px) rotate(0);
  }
  100% {
    -webkit-transform: translateY(0) rotate(0);
  }
}

@keyframes burger-bar01 {
  0% {
    transform: translateY(7px) rotate(45deg);
  }
  50% {
    transform: translateY(7px) rotate(0);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}

@-webkit-keyframes burger-bar03 {
  0% {
    -webkit-transform: translateY(-7px) rotate(-45deg);
  }
  50% {
    -webkit-transform: translateY(-7px) rotate(0);
  }
  100% {
    -webkit-transform: translateY(0) rotate(0);
  }
}

@keyframes burger-bar03 {
  0% {
    transform: translateY(-7px) rotate(-45deg);
  }
  50% {
    transform: translateY(-7px) rotate(0);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}

.scroll-prevent {
  position: fixed;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.drawermenu {
  z-index: -1;
  position: fixed;
  top: 0;
  right: 0;
  width: 85vw;
  height: 100%;
  overflow: auto;
  background-color: #F7F5EE;
  transition: opacity .3s ease-out;
  padding: 30px calc(20px + 20*(100vw - 320px)/1280) 30px calc(20px + 20*(100vw - 320px)/960);
  opacity: 0;
  -webkit-overflow-scrolling: touch;
  text-align: right;
  animation: drawermenu 1s cubic-bezier(0.65, 0.05, 0.36, 1) forwards;
}

@media only screen and (min-width: 480px) {
  .drawermenu {
    width: 60vw;
  }
}

@media only screen and (min-width: 768px) {
  .drawermenu {
    width: 40vw;
  }
}

.drawermenu.hide {
  opacity: 0;
}

.drawermenu.is-active {
  opacity: 1;
  z-index: 2000;
}

.drawermenu .drawermenu--burger-icon {
  cursor: pointer;
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
}

.drawermenu .drawermenu--burger-icon div {
  position: absolute;
  display: inline-block;
  transition: transform .3s;
  left: 0;
  width: 20px;
  height: 2px;
  background-color: #231815;
}

.drawermenu .drawermenu--burger-icon div:nth-of-type(1) {
  top: 50%;
  transform: rotate(45deg) translate(-50%, -50%);
  -webkit-animation: drawer-bar01 .75s forwards;
  animation: drawer-bar01 .75s forwards;
}

.drawermenu .drawermenu--burger-icon div:nth-of-type(2) {
  opacity: 0;
}

.drawermenu .drawermenu--burger-icon div:nth-of-type(3) {
  top: 50%;
  transform: rotate(-45deg) translate(-50%, -50%);
  -webkit-animation: drawer-bar03 .75s forwards;
  animation: drawer-bar03 .75s forwards;
}

@-webkit-keyframes drawer-bar01 {
  0% {
    -webkit-transform: translateY(0) rotate(0);
  }
  50% {
    -webkit-transform: translateY(0) rotate(0);
  }
  100% {
    -webkit-transform: translateY(0) rotate(45deg);
  }
}

@keyframes drawer-bar01 {
  0% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(0) rotate(0);
  }
  100% {
    transform: translateY(0) rotate(45deg);
  }
}

@-webkit-keyframes drawer-bar03 {
  0% {
    -webkit-transform: translateY(0) rotate(0);
  }
  50% {
    -webkit-transform: translateY(0) rotate(0);
  }
  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
  }
}

@keyframes drawer-bar03 {
  0% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(0) rotate(0);
  }
  100% {
    transform: translateY(0) rotate(-45deg);
  }
}

.drawer-nav--section .tel {
  font-family: "EB Garamond", serif;
}

.drawer-nav--section {
  border-top: 1px solid #dedede;
  padding-top: 20px;
  text-align: left;
}

.drawer-nav--section-first {
  border-top: none;
  padding-top: 0;
}

.drawer-nav--section .address {
  font-size: 0.875rem;
  line-height: 1.4;
  color: #767676;
}

.drawer-nav--section .address span {
  font-size: 0.75rem;
  display: block;
  color: #767676;
}

.drawer-nav--section .tel {
  font-size: calc(20px + 10*(100vw - 320px)/960);
  font-weight: 700;
  line-height: 1.3;
}

.drawer-nav--section .tel a {
  color: #ED4E84;
}

.drawer-nav--section .tel span {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
}

.drawer-nav--title {
  font-size: calc(14px + 4*(100vw - 320px)/960);
  margin-bottom: 10px;
}

.drawer-nav--title a {
  color: #231815;
  transition: color .3s ease-in-out;
}

.drawer-nav--title a:hover {
  color: #ED4E84;
}

.drawer-nav--item {
  line-height: 1.4;
}

.drawer-nav--item:not(:first-child) {
  margin-top: 10px;
}

.drawer-nav--item a {
  color: #231815;
  font-size: calc(14px + 4*(100vw - 320px)/960);
  transition: color .3s ease-in-out;
}

.drawer-nav--item a:hover {
  color: #ED4E84;
}

.drawer-nav--item a svg {
  height: 12px;
  width: auto;
  margin: 0 0 0 5px;
}

.drawer-nav--item .toggle {
  cursor: pointer;
}

.drawer-nav--item .toggle:hover span {
  color: #ED4E84;
}

.drawer-nav--item .toggle:hover span:after {
  border-top: 4px solid #ED4E84;
}

.drawer-nav--item .toggle span {
  display: inline-block;
  font-size: calc(14px + 4*(100vw - 320px)/960);
  line-height: 1.3;
  color: #231815;
  transition: color .3s ease-in-out;
}

.drawer-nav--item .toggle span:after {
  display: inline-block;
  content: '';
  width: 4px;
  height: 4px;
  border: 4px solid transparent;
  border-top: 4px solid #231815;
  margin-left: 5px;
  vertical-align: middle;
  transition: border-top .3s ease-in-out;
}

.drawer-nav--item .toggle.active span {
  color: #ED4E84;
}

.drawer-nav--item .toggle.active span:after {
  border-bottom: 4px solid #ED4E84;
  border-top: none;
}

.drawer-nav--item .child {
  padding-left: calc(10px + 20*(100vw - 320px)/960);
  margin: 0 0 10px;
  line-height: 1.3;
}

.drawer-nav--item .child.hide {
  display: none;
}

.drawer-nav--item .child--item {
  margin-top: 10px;
}

.page-cover {
  display: none;
}

.page-cover.is-active {
  display: block;
  z-index: 1900;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.slick-dots {
  bottom: 10vh;
  padding: 0 10%;
  display: flex;
  justify-content: space-between;
}

.slick-dots li {
  width: 40%;
  height: 6px;
}

@media only screen and (min-width: 768px) {
  .slick-dots li {
    height: 3px;
  }
}

.slick-dots li.slick-active {
  background-color: #fff;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
  left: 0;
  -webkit-animation: slick-bar 5s linear;
  animation: slick-bar 5s linear;
  background-color: #67AA1B;
}

.slick-dots li button:before {
  content: '';
  background-color: #231815;
  opacity: 0.4;
  width: 100%;
  height: 6px;
}

@media only screen and (min-width: 768px) {
  .slick-dots li button:before {
    height: 3px;
  }
}

@-webkit-keyframes slick-bar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes slick-bar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.slider:hover .slick-next {
  right: 3%;
}

.slider:hover .slick-prev {
  left: 3%;
}

.slick-next {
  right: -50px;
  transition: right .3s ease-in-out;
}

.slick-next:hover:before {
  opacity: 1;
}

.slick-prev {
  left: -50px;
  transition: left .3s ease-in-out;
  z-index: 1;
}

.slick-prev:hover:before {
  opacity: 1;
}

.slick-prev:before,
.slick-next:before {
  font-size: calc(20px + 20*(100vw - 320px)/960);
  opacity: .6;
  transition: opacity .3s ease-out;
  text-shadow: #767676 0 0 10px;
}

.nowrap {
  white-space: nowrap;
}

.kakomi {
  border-radius: 10px;
  border: solid 1px #ccc;
  padding: 10px;
  margin-bottom: 10px;
}

.sub-item {
  padding-left: 40px;
}

.verticallist .wpcf7-list-item {
  display: block;
}

.box33 {
  width: 33%;
  float: left;
}

.box66 {
  width: 66%;
  float: left;
}

/*
.loadingEffect {
    display: block;
    z-index: 100000;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
}

.loadingEffect::before, .loadingEffect::after {
    display: block;
    z-index: 99;
    position: fixed;
    right: 0;
    left: 0;
    width: 100%;
    height: 50%;
    margin-right: auto;
    margin-left: auto;
    background-color: #F5F5F5;
    line-height: 1;
    content: ' ';
    transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
    transition-delay: .3s;
}

.loadingEffect::before {
    top: 0;
}

.loadingEffect::after {
    bottom: 0;
}

.loaded .loadingEffect::before {
    height: 0;
    transform: translateY(-1%);
}

.loaded .loadingEffect::after {
    height: 0;
    transform: translateY(1%);
}
*/
.totel {
  color: #008D30;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "ヒラギノ角ゴシック Pro", "hiragino kaku gothic pro", meiryo, "メイリオ", sans-serif;
  font-size: calc(20px + 20*(100vw - 320px)/960);
  font-weight: 700;
}

.totel span {
  display: inline-block;
  margin: 0 10px 0 0;
  font-size: 1rem;
}

.news-list-top img {
  padding: 5px;
}

.sec-overseas ul {
  list-style-type: square !important;
  margin-left: 20px;
}
