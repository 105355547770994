@charset 'UTF-8';

// ========================================
// サイトの基本設定
// ========================================

// 変数設定
// ----------------------------------------

// カラー
/* 標準色 */
$black:      #231815;
/* ベースカラー */
$main:      #008D30;
$key:       #67AA1B;
$em:        #ED4E84;
$link:      #ED4E84;
/* 補助色 */
$aux:       #767676;
/* 線など */
$pale:      #dedede;
/* 薄い背景 */
$thin:      #F7F5EE;
$gray:      #F5F5F5;
/* オリジナル */

// フォント
$font: 'Noto Sans JP', meiryo, "メイリオ", sans-serif;
$font-min: 'Yu Mincho', 'YuMincho', 'Noto Serif JP','ＭＳ Ｐ明朝','MS PMincho', serif;
$font-en: 'EB Garamond', serif;
$font-load: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "ヒラギノ角ゴシック Pro", "hiragino kaku gothic pro", meiryo, "メイリオ", sans-serif;

// メディアクエリ
$breakpoints: (
    xs: 'only screen and (max-width: 479.98px)',
    sm: 'only screen and (min-width: 480px)',
    md: 'only screen and (min-width: 768px)',
    lg: 'only screen and (min-width: 1000px)',
    xl: 'only screen and (min-width: 1280px)',
    h: 'only screen and (min-height: 940px)',
);

$easeOutCubic: cubic-bezier(0.22, 0.61, 0.36, 1);
$easeInOutCubic: cubic-bezier(0.65, 0.05, 0.36, 1);



// 基本設定
// ----------------------------------------
*,
*::before,
*::after {
    box-sizing: border-box;
}
html {
    width: 100%;
    height: 100%;
}
.wf-active body {
    font-family: $font;
}
body {
    color: $black;
    font-size: 16px;
    font-family: $font-load;
    line-height: 1.4;
    font-weight: 400;
    width: 100%;
    height: 100%;
}
/*ie7*/
*:first-child+html body { font-size:75%; }
/*ie5-6*/
* html body { font-size:75%; }

// タッチデバイスの反応向上
// ----------------------------------------
a,
area,
button,
[role="button"],
input:not([type="range"]),
label,
select,
summary,
textarea {
-ms-touch-action: manipulation;
touch-action: manipulation; }

// 画像設定
// ----------------------------------------
img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}
a:hover img {
    opacity: .8;
}

figure {
    text-align: center;
}
figure {
    figcaption {
        font-size: calc(12px + 2*(100vw - 320px)/1280);
        color: $aux;
        text-align: center;
        margin: 5px 0 0;
    }
}



// リンク設定
// ----------------------------------------

a {
    color: $link;
    text-decoration: none;
    backface-visibility: hidden;
    &:hover {
        color: darken($link, 30%);
    }
}
a.line {
    text-decoration: underline;
    &:hover {
        text-decoration: none;
    }
}

// input
// ----------------------------------------
input[type="submit"] {
    background: none;
    border-radius: 0;
    -webkit-box-sizing: content-box;
    -webkit-appearance: button;
    appearance: button;
    border: none;
    box-sizing: border-box;
    font-size: 100%;
    cursor: pointer;
    &::-webkit-search-decoration {
        display: none;
    }
    &::focus {
        outline-offset: -2px;
    }
}
input[type="text"],
input[type="tel"],
input[type="email"],
select.full-width,
textarea {
    border: 1px solid $pale;
}


input[type="text"].full-width,
input[type="tel"].full-width,
input[type="email"].full-width,
select.full-width,
textarea.full-width {
    width: 100%;
}


// input
// ----------------------------------------
.slider {
    opacity: 0;
    transition: 3s;
    &.slick-initialized {
        opacity: 1
    }
}
